import React, { useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { BsInfoCircleFill } from "react-icons/bs";


const EnglishLevelTooltipCo = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>

      <BsInfoCircleFill
        onMouseEnter={() => handleShow()}
        className='text-info' />

      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Segmentos de nivel de inglés</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table style={{ fontSize: '14px' }} hover>
            <tbody>
              <tr>
                <th style={{ width: '150px' }}>Segmento A</th>
                <td>Puede trabajar sin problemas en inglés</td>
              </tr>
              <tr>
                <th>Segmento B</th>
                <td>Tiene dificultades para trabajar en inglés</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  )
}

export default EnglishLevelTooltipCo;
