import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import wolData from '../../helpers/wolData';
import WolSpinner from '../commons/WolSpinner';

type OpenToHireEmployer = {
  site: string;
  content: string;
  name: string;
  description: string;
  website_url: string;
  logo_url: string;
  logo_height: string;
  logo_width: string;
  listing_text: string;
  quote: null | string;
  location_link: string;
  landing_url: string;
  open_to_hire_job: OpenToHireJob;
  employer_jobs: EmployerJob[];
}

type EmployerJob = {
  id: number;
  site: string;
  state: string;
  title: string;
  subtitle: string;
  requirements: string[];
  salary: null | string;
  featured: boolean;
  hide_on_homepage: boolean | null;
  url: string;
  open_to_hire: null;
  content: string;
  city: string;
  employer: Employer;
}

type Employer = {
  name: string;
  logo_height: string;
  logo_width: string;
  website_url: string;
  logo_url: string;
  description: string;
  landing_url: string;
}

type OpenToHireJob = {
  id: number;
  site: string;
  state: string;
  title: string;
  subtitle: string;
  requirements: string[];
  salary: null | string;
  featured: boolean;
  hide_on_homepage: boolean;
  url: string;
}

const getOpenToHireUrl = () => { return wolData.urls.APIUrl + "/open_to_hire" }

const OpenToHire = () => {
  const [openToHireJobs, setOpenToHireJobs] = useState<OpenToHireEmployer[]>([])
  const [areOpenToHireJobs, setAreOpenToHireJobsLoaded] = useState(false);

  const getData = async () => {
    try {
      const response = await fetch(getOpenToHireUrl(), {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') }
      })
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        setOpenToHireJobs(data.open_to_hire_employers)
        setAreOpenToHireJobsLoaded(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => { getData() }, []);

  return areOpenToHireJobs ? (
    <Container className='wol-container mb-5'>
      <Row className='mb-4'>
        <Col>
          <h2>Postulación y Carrera</h2>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <h4>¿Quieres que tus antecedentes profesionales sean visibles a alguno de los siguientes empleadores?</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>A continuación podrás ver un listado con oficinas de abogados de alto nivel que tienen interés por estar en contacto con el talento legal. También encontrarás en la lista a reclutadores habituales como Idealis Legal Recruitment. En esta sección podrás postular a procesos activos con ellos, o bien dejar tus antecedentes para que sean considerados en futuros procesos de selección.</p>
        </Col>
      </Row>
      {openToHireJobs.map((e) => (
        <Row
          className="align-items-center mh-230"
          key={`openToHire-${e.name}`}
          style={{
            borderBottom: '1px solid #E5E5E5',
            paddingBottom: '10px',
            marginTop: '10px',
            marginBottom: '50px',
            height: '230px'
          }}
        >
          {/* Employer logo */}
          <Col md={3} xs={4}>
            <img
              width={e.logo_width}
              height={e.logo_height}
              src={e.logo_url}
              alt={'employer logo'}
            >
            </img>
          </Col>

          {/* Employer name and job title*/}
          <Col md={4} xs={8}>
            <span
              className="job-title-link"
              style={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                fontWeight: 700,
                fontStyle: 'normal',
                lineHeight: '25px',
                color: '#1F2A66'
              }}>
              <a href={wolData.urls.homepageUrl + "/" + e.open_to_hire_job.url}>{e.open_to_hire_job.title}</a>
            </span>
            <br></br>
            <span
              style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '25px'
              }}
            >
              <a style={{ color: '#960F0F', fontWeight: '500' }} href={e.website_url}>{e.name}</a>
            </span>
          </Col>
          <Col className="info-xs-hidden" md={2}>
            <a
              href={wolData.urls.homepageUrl + "/empleadores/" + e.landing_url}
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-link"
            >
              Ver empleador
            </a>
          </Col>
          <Col>
            {<a
              href={"/apply-to?job_id=" + e.open_to_hire_job.id + "&candidate_id=" + localStorage.getItem("candidateId")}
              className='btn btn-outline-primary btn-block'
            >
              Inscripción
            </a>}
          </Col>
        </Row>
      ))}
    </Container>
  ) : (
    <WolSpinner />
  )
}

export default OpenToHire;