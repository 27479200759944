import { Dispatch, SetStateAction, useState } from 'react';
import { Row, Col, Form, Button, Table, Alert } from 'react-bootstrap';
import { getWorkExperienceYearsOptions, englishLevelOptions } from './Shared';

import Select from 'react-select';
import utils from '../../helpers/utils';
import wolData from '../../helpers/wolData';
import { Option, ProfileResultsData , Results as ResultsData } from "./SharedTypes";

type OptionsProps = {
  results?: ResultsData;
  englishLevel: Option | null;
  setEnglishLevel: Dispatch<SetStateAction<Option | null>>;
  workExperienceYears: Option | null;
  setWorkExperienceYears: Dispatch<SetStateAction<Option | null>>;
  setCalculatedResults: Dispatch<SetStateAction<ProfileResultsData | null>>;
}

export const getResults = async (
                                  setCalculatedResults: Dispatch<SetStateAction<ProfileResultsData | null>>,
                                  workExperienceYears: Option | null,
                                  englishLevel: Option | null,
                                  results?: ResultsData | null
                                ) => {
  if (
    !(results) ||
    !(results.salary_data) ||
    !(results.salary_data.salary) ||
    !(results.salary_data.salary.key) ||
    !(results.salary_data.salary.cluster) ||
    workExperienceYears === null ||
    englishLevel === null
  ) {
    return null
  };

  const url = `${wolData.urls.APIUrl}/candidates/salary_calculator_results`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
    body: JSON.stringify({
      current_key: JSON.stringify(results?.salary_data?.salary?.key),
      current_cluster: results?.salary_data?.salary?.cluster,
      calculated_experience_key: workExperienceYears.value,
      calculated_english_level: englishLevel.value
    })
  };

  const res = await utils.fetch(url, requestOptions);
  if(res.status === 200){
    setCalculatedResults(res.body);
  }
}

export const optionsSelected = (workExperienceYears: Option | null, englishLevel: Option | null) =>{
  return ((workExperienceYears !== null) && (englishLevel !== null));
}

export const Options = ({
                  results,
                  englishLevel,
                  setEnglishLevel,
                  workExperienceYears,
                  setWorkExperienceYears,
                  setCalculatedResults
                }: OptionsProps) => {

  return (
    <Row className='mt-5'>
      <Col>
        <Form>
          <Row>

            <Col lg='4'>
              <Form.Group>
                <Select
                  placeholder={"Selecciona experiencia profesional"}
                  isClearable={true}
                  value={workExperienceYears}
                  options={getWorkExperienceYearsOptions(results?.work_experience_years)}
                  onChange={(option: any) => setWorkExperienceYears(option) }
                />
              </Form.Group>
            </Col>

            <Col lg='4'>
              <Form.Group>
                <Select
                  placeholder={"Selecciona nivel de inglés"}
                  isClearable={true}
                  value={englishLevel}
                  options={englishLevelOptions}
                  onChange={(option: any) => setEnglishLevel(option) }
                />
              </Form.Group>
            </Col>

            <Col lg='4'>
              <Button variant="danger"
                      disabled={!(optionsSelected(workExperienceYears, englishLevel))}
                      className='btn-block'
                      onClick={() => {getResults(setCalculatedResults, workExperienceYears, englishLevel, results)}}>
                Calcular
              </Button>
            </Col>

          </Row>
        </Form>

      </Col>
    </Row>
  )
}

export const Instructions = ({ results }: {results?: ResultsData}) => {
  if(!(results) || !(results.work_experience_years) || !(results.english_level_label)) { return '' }

  return (
    <Row className='mt-5'>
      <Col>
        <Alert variant='info'>
          Selecciona experiencia profesional y nivel de inglés para revelar el impacto en el sueldo de un abogado con <strong>{results?.work_experience_years} años de experiencia</strong> y nivel de inglés <strong>{results?.english_level_label}</strong>.
        </Alert>
      </Col>
    </Row>
  )
}


export const getEnglishLevelTierLabel = (tier: number) => {
  if(tier == 1){
    return 'Puede trabajar en inglés'
  }else{
    return 'No puede trabajar en inglés'
  }
}

export const ProfileResults = ({ title, results }: { title: string; results?: ProfileResultsData | null }) => {
  if( !(results) ||
      (!(results.work_experience_years) && results.work_experience_years !== 0) ||
      !(results.p20) ||
      !(results.p90) ||
      !(results?.key?.english_level) ){

    return '';
  }

  return (
    <Col lg='6' className='mt-3' >

      <h5 className='text-center mb-2 bg-light' style={{color: '#1F2A66', padding: '15px'}} >
        {title}
      </h5>

      <Row>
        <Col className='ml-5'>
          <Table borderless>
            <tbody>
              <tr>
                <td>Experiencia profesional</td>
                <td>{ (results.work_experience_years === 11) ? '11 a 14' : results.work_experience_years} años</td>
              </tr>
              <tr>
                <td>Nivel de inglés</td><td>{getEnglishLevelTierLabel(results.key.english_level)}</td>
              </tr>
              <tr>
                <td>Piso líquido</td><td>{utils.formatSalary(results.p20)}</td>
              </tr>
              <tr>
                <td>Techo líquido</td><td>{utils.formatSalary(results.p90)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Col>
  )
}



export const SalaryCalculatorResults =  (
                                          { results, calculatedResults }:
                                          {
                                            results?: ResultsData | null;
                                            calculatedResults?: ProfileResultsData | null;
                                          }
                                        ) => {

  if(!(results) || !(results.salary_data) || !(results.salary_data.salary)) { return '' }

  if (calculatedResults && calculatedResults.error_message) {
    return (
      <Row className='mt-5'>
        <Col>
          <Alert variant='warning'>
            No es posible calcular resultados.
          </Alert>
        </Col>
      </Row>
    )
  }

  const currentResult: ProfileResultsData = {
    work_experience_years: results.work_experience_years,
    p20: results.salary_data.salary.p20,
    p90: results.salary_data.salary.p90,
    key: results.salary_data.salary.key,
    error_message: null,
  }

  if((calculatedResults)){
    utils.trackMixpanelEvent('ShowMeTheMoney: Salary calculator results');
    return(
      <Row className='mt-5'>
        <ProfileResults title={'Perfil actual'} results={currentResult}/>
        <ProfileResults title={'Perfil calculado'} results={calculatedResults}/>
      </Row>
    )
  }else{
    return '';
  }
}

const SalaryCalculator = ({ results }: { results?: ResultsData }) => {
  const [workExperienceYears, setWorkExperienceYears] = useState<Option | null>(null)
  const [englishLevel, setEnglishLevel] = useState<Option | null>(null)
  const [calculatedResults, setCalculatedResults] = useState<ProfileResultsData | null>(null)

  utils.trackMixpanelEvent('ShowMeTheMoney: Salary calculator view');
  return(
    <Row className='mt-4'>
      <Col>

        <h5>Calculadora de sueldo: Inglés</h5>

        <Instructions results={results} />

        <Options  results={results}
                  englishLevel={englishLevel}
                  setEnglishLevel={setEnglishLevel}
                  workExperienceYears={workExperienceYears}
                  setWorkExperienceYears={setWorkExperienceYears}
                  setCalculatedResults={setCalculatedResults}
                  />

        <SalaryCalculatorResults results={results} calculatedResults={calculatedResults}/>

      </Col>
    </Row>
  )
}

export default SalaryCalculator;
