import { EnglishData } from "./SharedTypes"

type ResultsData = {
  name: string;
  email: string;
  university: string;
  university_graduation_year: number;
  english_data: EnglishData;
}

const EnglishLevelResults = ({ results }: { results: ResultsData }) => {
  if (results.university_graduation_year === -1) {
    if (results && results.english_data && results.english_data.display_university_name) {
      return <p>A continuación el nivel de inglés correspondiente a los no egresados de Derecho de la {results.university}</p>;
    } else {
      return <p>A continuación el nivel de inglés correspondiente a los <strong>no egresados de Derecho en Chile</strong>.</p>;
    }
  } else {
    if (results && results.english_data && results.english_data.display_university_name) {
      return <p>A continuación el nivel de inglés correspondiente a los egresados de Derecho de la <strong>{results.university}</strong> el año <strong>{results.university_graduation_year}</strong>.</p>;
    } else {
      return <p>A continuación el nivel de inglés correspondiente a los egresados de Derecho en Chile el año <strong>{results.university_graduation_year}</strong>.</p>;
    }
  }
}

export default EnglishLevelResults;
