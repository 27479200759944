import React from 'react';
import { useFetch } from 'react-async';
import wolData from "../../helpers/wolData";
import WolSpinner from "../commons/WolSpinner";
import { Badge, Col, Row } from "react-bootstrap";
import { MdCheckCircleOutline, MdOutlineCircle } from "react-icons/md";
import ReactGA from 'react-ga';
import CriteriaTestButton from './CriteriaTestButton';


const TestStatus = ({ name, status }) => {
  return <div className={"mb-2"}>
    <span className={"mr-2"}>
      <span className={"mr-2"}>{status ? <MdCheckCircleOutline /> : <MdOutlineCircle />}</span>
      {name}:
    </span>
    <Badge variant={(status === 'completed') ? "success" : "secondary"}>
      {status ? "Rendido" : "No rendido"}
    </Badge>
  </div>
}

const CriteriaProfile = () => {
  const { data, error, isPending } = useFetch(`${wolData.urls.APIUrl}/candidate_criteria_state`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  });

  if (isPending) return <WolSpinner />;
  if (error || data.errors) {
    if (data.errors && data.errors.invalid_token) {
      localStorage.removeItem('jwt');
      window.location.href = wolData.urls.frontUrl + "/sign-in";
      ReactGA.event({
        category: 'candidate',
        action: 'profile4-with-errors'
      });
    }
  }

  return(
    <Row>
      <Col>
        <div className='wol-form'>
          <h2 className='mb-5' style={{fontWeight: 500}}>Pruebas psicolaborales</h2>

          <div className={"mt-5 mb-3"}>
            <TestStatus name={"Test de personalidad"} status={data.epp} />
            <TestStatus name={"Test de habilidades cognitivas"} status={data.ucat} />
          </div>

          <CriteriaTestButton data={data}/>
        </div>
      </Col>
    </Row>
  )
}

export default CriteriaProfile;