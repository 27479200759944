import React from 'react';
import {Alert, Button } from 'react-bootstrap'

const getAlertMessage = (flow) => {
  if (flow === 'application') {
    return <Alert variant="light" className='mb-4' style={{ fontSize: '14px' }}>
      Estás postulando a <strong>{localStorage.getItem('applyingToJobTitle')}</strong>
    </Alert>
  }

  return null;
}

const getSubmitText = (flow) => {
  if(flow === 'application'){ return 'Continuar Postulación' }
  if(flow === 'salary'){ return 'Show me the money' }
  return 'Guardar';
}

const CustomFlowSubmit = ({ formFlow, profile, isSubmitting }) => {
  return (
    <React.Fragment>
      <br></br>
      {getAlertMessage(formFlow)}

      <Button
        className='mb-4 p-3'
        size="lg"
        variant="danger"
        type="submit"
        disabled={isSubmitting}
      >
        {getSubmitText(formFlow)}
      </Button>
    </React.Fragment>
  )
}
export default CustomFlowSubmit;
