import wolData from './wolData';
import mixpanel from 'mixpanel-browser';

const areWeTesting = () => process.env.JEST_WORKER_ID !== undefined;

const utils = {

  fetch: async (url, request) => {
    try {
      const response = await fetch(url, request);
      const data = await response.json();

      return ({
        status: response.status,
        body: data
      })
    } catch (error) {
      console.error(error);
      return {status: null, body: null}
    }
  },


  trackMixpanelEvent: (eventName, props = {}) => {
    if(process.env.NODE_ENV !== 'production'){ return null }

    let defaultProps = {
      email: localStorage.getItem('userEmail'),
      site: localStorage.getItem('userSite')
    }

    if (typeof props === 'object' && props !== null && Object.keys(props).length > 0) {
      defaultProps = Object.assign(defaultProps, props);
    }

    mixpanel.track(eventName, defaultProps);
  },

  getLanguage: (siteParam) => {
    if (siteParam === 'CO') {
      return 'es-CO';
    }
    if (siteParam === 'MX') {
      return 'es-MX';
    }
    return 'es-CL';
  },

  getSalariesBySite: (candidateSite) => {
    if (candidateSite === 'CL') {
      return wolData.chileanSalaries;

    } else if (candidateSite === 'CO') {
      return wolData.colombianSalaries;

    } else if (candidateSite === 'MX') {
      return wolData.mexicanSalaries;

    } else {
      return [];
    }
  },

  getDriversBySite: (site) => {
    if (site === "CL") {
      return wolData.drivers;

    } else if (site === "CO" || site === "MX") {
      return wolData.drivers.filter(driver => driver.value !== "8");

    } else {
      return null;
    }
  },

  universityGraduationYearDefaultValues: (fromYear, toYear) => {
    // Build array with options data
    let years = [{ value: '', label: '' }, { value: '-1', label: 'No he egresado' }];
    while (toYear > fromYear) {
      years.push({ value: toYear.toString(), label: toYear.toString() });
      toYear = toYear - 1;
    }
    years.push({ value: fromYear.toString(), label: fromYear.toString() });

    // Return options
    return years.map((year) => (
      <option key={year.value} value={year.value}>
        {year.label}
      </option>
    ));
  },

  candidateSpecialitiesAttributesRequestObject: (primarySpeciality, otherSpecialities) => {
    let candidateSpecialities = []
    let specialitiesIds = []

    // Validate blank option
    if (primarySpeciality !== "") {
      candidateSpecialities.push({ primary: true, speciality_id: primarySpeciality });
    }

    if (otherSpecialities && otherSpecialities.length > 0) {
      for (let i = 0; i < otherSpecialities.length; i++) {
        // Send to API only if speciality was not included previously as a primary speciality, sending it 2 times doesn't make sense
        // If blank option is selected, option is not sent either
        if (!specialitiesIds.includes(otherSpecialities[i].value) && otherSpecialities[i].value !== "") {
          candidateSpecialities.push({ speciality_id: otherSpecialities[i].value })
        }
      }
    }
    return candidateSpecialities;
  },

  displayFileData: (fileValues) => {
    if (fileValues === null) {
      return null
    } else {
      return (
        <div style={{ paddingTop: '10px' }}>
          <a href={wolData.urls.APIUrl + fileValues.url} className="btn btn-link" target="_blank" rel="noreferrer">
            {fileValues.filename}
          </a>
        </div>
      )
    }
  },

  fieldClassName: (field, status, touched, errors) => {
    let name = "";
    if (touched[field] && errors[field]) {
      name = "error"
    }
    if (status && status[field]) {
      name = "error"
    }
    return name;
  },

  displayFieldError: (field, status, touched, errors) => {
    if (status && status[field]) {
      return <div className="error-message">{status[field]}</div>
    } else {
      if (touched[field] && errors[field]) {
        return <div className="error-message">{errors[field]}</div>
      }
    }
  },

  getWorkplacesForSite: async (site) => {
    if (areWeTesting()) {
      return [];
    }

    if (!wolData.validSites.includes(site.toUpperCase())) {
      return [];
    } else {
      let cache = JSON.parse(localStorage.getItem(`workplace:${site.toUpperCase()}`) || "{}");
      if (Object.keys(cache).length === 0 || ((Math.abs(Date.parse(cache["lastUpdate"]) - new Date()) / 1728000) > 1)) {
        let response = await fetch(
          `${wolData.urls.APIUrl}/workplaces/${site.toLowerCase()}`,
          { headers: {"Authorization": `Bearer ${localStorage.getItem("jwt")}`} }
        );
        cache = { content: await response.json(), lastUpdate: new Date() }
        localStorage.setItem(`workplace:${site.toUpperCase()}`, JSON.stringify(cache))
      }
      return cache['content'];
    }
  },

  getPastJobs: async (candidateSite) => {
    if (typeof candidateSite !== 'string') candidateSite = 'cl';
    const defaultWorkplaces = await utils.getWorkplacesForSite(candidateSite)
    return defaultWorkplaces;
  },

  formatSalary: (amount) => {
    return `$ ${Intl.NumberFormat('es-ES').format(amount)}`
  }
}
export default utils;
