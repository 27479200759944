import { Route } from "react-router-dom";

const Auth = ({ component, path, toRedirect, tokenRequired }) => {

  const option = (localStorage.getItem("jwt") !== null) === tokenRequired ? (
    <Route exact path={path}>
      {component}
    </Route>
  ) : (
    <Route>
      {toRedirect}
    </Route>
  )

  return <div>{option}</div>
}

export default Auth;