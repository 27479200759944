
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';

type FormValues = {
  password: string,
  new_password: string,
  confirm_new_password: string
}

const ChangePasswordForm = () => {

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Ingrese contraseña anterior").min(8, "Ingrese a lo menos 8 caracteres"),
    new_password: Yup.string().required("Ingrese nueva contraseña").min(8, "Ingrese a lo menos 8 caracteres").matches(/[0-9]/, "Contraseña debe tener al menos un dígito"),
    confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], "Contraseña no coincide").required("Confirme su nueva contraseña")
  });

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      const { setValues, setTouched, setSubmitting, setStatus } = helpers
      setSubmitting(true);
      setTimeout(() => { setSubmitting(false) }, 500);

      const requestOptions = {
        method: 'put',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
        body: JSON.stringify({
          password: values.password,
          new_password: values.new_password,
          email: localStorage.getItem('userEmail')
        })
      };

      const response = await fetch((wolData.urls.APIUrl + "/candidates/" + localStorage.getItem('candidateId') + "/change_password"), requestOptions)
      const data = await response.json();
      if (data.errors) {
        if (data.errors.password) { setStatus({ password: data.errors.password }) }
        if (data.errors.new_password) { setStatus({ new_password: data.errors.new_password }) }
        if (data.errors.general) { setStatus({ general: data.errors.general }) }
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        (document.activeElement as HTMLElement).blur();
        setStatus({ successFormSubmission: true })
        setValues({ password: '', new_password: '', confirm_new_password: '' })
        setTouched({ password: false, new_password: false, confirm_new_password: false })
        ReactGA.event({ category: 'candidate', action: 'change-password' });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Formik
      initialValues={{ password: "", new_password: "", confirm_new_password: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {
        ({ values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting }) => (

          <Container className='wol-container mb-5'>
            <Row className='mb-4'>
              <Col>
                <h2>Cambiar contraseña</h2>
                {status && status.successFormSubmission ? <Alert variant='success'>Contraseña actualizada exitosamente</Alert> : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form onSubmit={handleSubmit} className='wol-form'>

                  <Form.Group className='mb-5'>
                    <Form.Label>Ingrese contraseña anterior o contraseña temporal de recuperación</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className={utils.fieldClassName('password', status, touched, errors)}
                    />
                    {utils.displayFieldError('password', status, touched, errors)}
                  </Form.Group>

                  <Form.Group className='mb-5'>
                    <Form.Label>Ingrese nueva contraseña</Form.Label>
                    <Form.Control
                      name="new_password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.new_password}
                      className={utils.fieldClassName('new_password', status, touched, errors)}
                    />
                    {utils.displayFieldError('new_password', status, touched, errors)}
                  </Form.Group>

                  <Form.Group className='mb-5'>
                    <Form.Label>Confirme nueva contraseña</Form.Label>
                    <Form.Control
                      name="confirm_new_password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_new_password}
                      className={utils.fieldClassName('confirm_new_password', status, touched, errors)}
                    />
                    {utils.displayFieldError('confirm_new_password', status, touched, errors)}
                  </Form.Group>

                  <Button
                    variant="danger"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Enviar
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        )
      }
    </Formik>
  )
}
export default ChangePasswordForm;
