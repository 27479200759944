import React from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaUserCircle } from "react-icons/fa";

import wolData from '../../helpers/wolData';
import session from '../../helpers/session';
import utils from '../../helpers/utils';
import logo from "../../images/logo.svg"


const logout = async () => {
  const url = `${wolData.urls.APIUrl}/auth/sign_out`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
      'Content-Type': 'application/json'
    }
  }
  utils.fetch(url, requestOptions);
  session.destroy();
  window.location.href = wolData.urls.signIn;
}

export const renderNotSignedInNavbarLinks = () => {
  return (
    <React.Fragment>
      <Nav.Link href={wolData.urls.homepageUrl}>
        Trabajos para abogados
      </Nav.Link>
      <Nav.Link href={wolData.urls.blog}>
        Blog
      </Nav.Link>
      <Nav.Link href={ wolData.urls.signIn }>
        <Button variant="outline-danger">Inicio de sesión</Button>
      </Nav.Link>
      <Nav.Link href={ wolData.urls.signUp }>
        <Button variant="danger">Regístrate</Button>
      </Nav.Link>
    </React.Fragment>
  )
}

export const renderSignedInNavbarLinks = (site: string | null, userFullName: string | null, userEmail: string | null) => {
  const userMenuItem = <span style={{ paddingLeft: '10px' }}><FaUserCircle size={24} /></span>;

  return (
    <React.Fragment>
      <Nav.Link href={ wolData.urls.jobs }>Trabajos Disponibles</Nav.Link>
      <Nav.Link href={ wolData.urls.profile }>Perfil</Nav.Link>
      <Nav.Link href={ wolData.urls.showMeTheMoney }>Sueldos</Nav.Link>
      <Nav.Link href={ wolData.urls.applicationAndCareer }>Postulación y carrera</Nav.Link>
      <Nav.Link target='_blank' href={ wolData.urls.blog }>Blog</Nav.Link>
      <NavDropdown id='navbar-dropdown' title={userMenuItem} alignRight>
        <NavDropdown.Item disabled className="text-center font-weight-bolder">
          <div style={{marginBottom: '-20px'}} >{ userFullName }</div>
          <small>{ userEmail }</small>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href={ wolData.urls.changePassword }>Cambiar Contraseña</NavDropdown.Item>
        <NavDropdown.Item onClick={logout}>Cerrar Sesión</NavDropdown.Item>
      </NavDropdown>
    </React.Fragment>
  )
}

export const renderNavbarBrand = () => {
  return(
    <a className="navbar-brand" href={wolData.urls.homepageUrl}>
      <img src={logo} alt='Work on law' />
    </a>
  )
}

const Header = () => {
  const userSite = localStorage.getItem('userSite');
  const jwt = localStorage.getItem("jwt");

  let navbarLinks = null;
  if(jwt === null){
    navbarLinks = renderNotSignedInNavbarLinks();

  }else{
    const userFullName = localStorage.getItem("userName") + " " + localStorage.getItem("userSurname");
    const userEmail = localStorage.getItem("userEmail");
    navbarLinks = renderSignedInNavbarLinks(userSite, userFullName, userEmail);
  }

  return (
    <Navbar id="wol-navbar" bg="white" expand="lg">

      { renderNavbarBrand() }

      <Navbar.Toggle aria-controls="navbar-links" />
      <Navbar.Collapse id="navbar-links">
        <Nav className="justify-content-end" style={{ width: "100%" }}>
          { navbarLinks }
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  )
}

export default Header;
