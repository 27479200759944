import { Container, Row, Col, Button } from "react-bootstrap"

import wolData from '../../helpers/wolData';

const ApplicationIntro = ({ site, openToHireId }) => {
  let signUpUrl = wolData.urls.frontUrl + "/sign-up"
  if (openToHireId && openToHireId !== 'null' && openToHireId !== 'undefined') {
    signUpUrl = signUpUrl + "?open_to_hire_id=" + openToHireId;
  }

  let signInUrl = wolData.urls.frontUrl + "/sign-in"
  if (openToHireId && openToHireId !== 'null' && openToHireId !== 'undefined') {
    signInUrl = signInUrl + "?open_to_hire_id=" + openToHireId;
  }

  return (
    <Container style={{ marginTop: '25px' }} className='wol-container mb-5'>
      <Row>
        <Col>
          <h2>Postulación</h2>
          <p>
            Postular implica responder algunas preguntas y crear tu perfil para los empleadores. Solo necesitas hacerlo una vez, luego usarás los mismos datos para todas tus postulaciones.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: '25px' }}>
        <Col xs={11} md={5} className="text-center" style={{ margin: '25px', padding: '25px', backgroundColor: '#F4F7FF' }}>
          <h3 style={{ color: '#1F2A66' }}>¿Tienes una cuenta?</h3>
          <br></br>
          <strong style={{ color: '#191919', fontSize: '15px' }}>Si ya tienes un usuario en Work On Law, solo debes iniciar sesión y seleccionar la oferta desde el listado de trabajos disponibles.</strong>
          <br></br>
          <br></br>
          <Button href={signInUrl} variant="outline-danger" size="lg" className="btn-block">Inicio de sesión</Button>
        </Col>
        <Col xs={11} md={5} className="text-center" style={{ margin: '25px', padding: '25px', backgroundColor: '#F4F7FF' }}>
          <h3 style={{ color: '#1F2A66' }}>No tengo cuenta</h3>
          <br></br>
          <strong style={{ color: '#191919', fontSize: '15px' }}>Si no tienes un usuario en Work On Law, a continuación puedes crear uno. Luego puedes iniciar sesión y seleccionar la oferta desde el listado de trabajos disponibles.</strong>
          <br></br>
          <br></br>
          <Button href={signUpUrl} variant="danger" size="lg" className="btn-block">Registrate</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ApplicationIntro;