import { useState } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'
import Select from 'react-select';

import { postgraduateOptions, getWorkExperienceYearsOptions } from './Shared';
import { Option, Results } from './SharedTypes';

const PosgraduateSalaryCalculator = ({ results }: { results?: Results }) => {
  const [workExperienceYears, setWorkExperienceYears] = useState<Option | null>(null)
  const [postgraduate, setPostgraduate] = useState<Option | null>(null)

  return(
    <Row>
      <Col className='mt-4'>

        <Row>
          <Col>
            <h5>Calculadora de sueldo: Posgrado (próximamente)</h5>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
          <Alert variant='info'>
            Pronto podrás revelar el impacto de un posgrado en tu sueldo
          </Alert>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <Form>
              <Row>

                <Col lg='4'>
                  <Form.Group>
                    <Select
                      placeholder={"Selecciona experiencia profesional"}
                      isClearable={true}
                      value={workExperienceYears}
                      options={getWorkExperienceYearsOptions(results?.work_experience_years)}
                      onChange={(option: any) => setWorkExperienceYears(option)}
                    />
                  </Form.Group>
                </Col>

                <Col lg='4'>
                  <Form.Group>
                    <Select
                      placeholder={"Selecciona posgrado"}
                      isClearable={true}
                      value={postgraduate}
                      options={postgraduateOptions}
                      onChange={(option: any) => setPostgraduate(option)}
                    />
                  </Form.Group>
                </Col>

                <Col lg='4'>
                  <Button variant="light"
                    disabled={true}
                    className='btn-block'
                    onClick={() => { console.log('Calculate postgraduate salary') }}>
                    Calcular
                  </Button>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>

      </Col>
    </Row>
  )
}

export default PosgraduateSalaryCalculator;
