import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const getIcon = (active) => {
  if (active === true) {
    return <MdCheckBox />;
  } else {
    return <MdCheckBoxOutlineBlank />;
  }
}

const getClass = (active, variant) => {
  const textVariant = variant === 'text-secondary' ? variant : 'text-success';
  if (active === true) {
    return 'application-steps-check-container ' + textVariant;
  } else {
    return 'application-steps-check-container';
  }  
}

const ApplicationStepCheckbox = ({ active, textVariant}) => {
  return (<span className={getClass(active, textVariant)}>{getIcon(active)}</span>)
}
export default ApplicationStepCheckbox;
