import { Form } from 'react-bootstrap'
import utils from '../../helpers/utils'
import { consentText } from './Application'

type ApplicationData = {
  candidate: {consent: boolean},
  job: {employer_name: string}
}

type Props = {
  applicationData: ApplicationData,
  handleChange: any,
  handleBlur: any,
  values: any,
  status: any,
  touched: any,
  errors: any
}

const displayBakerTermsLink = (applicationData: ApplicationData) => {
  if(applicationData.job &&
     applicationData.job.employer_name &&
     applicationData.job.employer_name === 'Baker & McKenzie Colombia'){

    return (
      <small>
        <a href="https://wol-logos.s3.amazonaws.com/Autorizacio%CC%81n+tratamiento+de+datos+personales.pdf"
           target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: 'underline' }}>

          Ver autorización para tratamiento de información de empleador Baker & McKenzie
        </a>
      </small>
    )
  }
  return null;
}

const ConsentFields = ({ applicationData, handleChange, handleBlur, values, status, touched, errors }: Props) => {
  if ( !(applicationData) ) { return null };

  if ( applicationData.candidate && applicationData.candidate.consent === true ){
    return null
  };

  return (
    <Form.Group>
      <Form.Label>
        { consentText }
        { displayBakerTermsLink(applicationData) }
      </Form.Label>

      <Form.Check
        name="consent"
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.consent} />

      { utils.displayFieldError('consent', status, touched, errors) }
    </Form.Group>
  )
}

export default ConsentFields;
