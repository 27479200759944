import { Row, Col, Button } from "react-bootstrap";
import ReactGA from 'react-ga';
import utils from "../../helpers/utils";

const CriteriaTestButton = ({data}) => {
  if(data.epp === 'completed' && data.ucat === 'completed'){ return '' };

  return (
    <Row className='mt-4'>
      <Col md={3} className='mt-4'>
        <Button href={data.link}
                variant='outline-danger'
                className='btn-block'
                onClick={() => {
                  utils.trackMixpanelEvent('Criteria profile tests button clicked');
                  ReactGA.event({ category: 'candidate', action: 'profile4-clicked-tests-button' });
                }}>

          Rendir tests faltantes
        </Button>
      </Col>

      <Col style={{ fontSize: '14px', fontStyle: 'italic', padding: '15px 30px 15px 30px' }}
           md={9}
           className='mt-2'>

        Si ya realizaste las pruebas psicolaborales, no es necesario volver a rendirlas. La información de esta sección podría tardar algunos días en actualizarse.
      </Col>
    </Row>
  )
}

export default CriteriaTestButton;
