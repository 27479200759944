import React from 'react';
import {
  Container,
  Row,
  Col,
  Spinner
} from 'react-bootstrap'

const WolSpinner = () => {
  return (
    <React.Fragment>
      <Container style={{ marginTop: '35px', marginLeft: '80px' }}>
        <Row>
          <Col>
            <Spinner animation="border" role="status">
              <span className="sr-only"> Buscando información ...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default WolSpinner;