import { useTranslation } from 'react-i18next';
import { Row, Col, Table } from 'react-bootstrap';

export type Props = {
  applicationData: {
    job: {
      state: string;
      pretension_of_income_stat: string;
      university_graduation_year_stat: string;
      work_experience_years_stat: string;
    },
    application: {
      application_date: string;
      application_pretension_of_income: string;
    }
  }
}

const Stats = ({ applicationData }: Props) => {
  const { t } = useTranslation("global");

  if ( applicationData.job.state !== 'closed' ) { return '' };

  return (
    <Row>
      <Col>
        <br /><br />
        <Table>
          <tbody>
            <tr>
              <td style={{ width: '300px' }}>{t('candidateApplication.statsTable.applicationDateLabel')}</td>
              <td>{applicationData.application.application_date}</td>
            </tr>
            <tr>
              <td>{t('candidateApplication.statsTable.pretensionOfIncomeLabel')}</td>
              <td>{applicationData.application.application_pretension_of_income}</td>
            </tr>
            <tr>
              <td>{t('candidateApplication.statsTable.pretensionOfIncomeStatLabel')}</td>
              <td>{applicationData.job.pretension_of_income_stat}</td>
            </tr>
            <tr>
              <td>{t('candidateApplication.statsTable.universityGraduationYearStatLabel')}</td>
              <td>{applicationData.job.university_graduation_year_stat}</td>
            </tr>
            <tr>
              <td>{t('candidateApplication.statsTable.workExperienceYearsStatLabel')}</td>
              <td>{applicationData.job.work_experience_years_stat}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default Stats;
