import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Tabs, Tab, Form, Button, InputGroup } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";
import ReactCountryFlag from "react-country-flag"
import ReactGA from 'react-ga';

import wolData from '../../helpers/wolData';
// import utils from '../../helpers/utils';
import mixpanel from 'mixpanel-browser';
import ListItemJob from './ListItemJob';
import WolSpinner from '../commons/WolSpinner';
import { UniversityListingBanner } from '../commons/UniversityBanner';

type Job = {
  id: number;
  site: string;
  state: string;
  title: string;
  subtitle: string;
  requirements: string[];
  salary: null | string;
  featured: boolean;
  hide_on_homepage: boolean;
  url: string;
  open_to_hire: null;
  content: string;
  city: string;
  employer: Employer;
  application_state: string;
  external_url: null;
  original_job_to_apply_url: null;
}

type Employer = {
  name: string;
  logo_height: string;
  logo_width: string;
  website_url: string;
  logo_url: string;
  description: string;
  landing_url: string;
}

const universityMessage = (universityName: string | null) => {
  if (universityName === null) {
    return null;
  }

  const candidateUniversity = wolData['activeUniversities'].find(u => u.name === universityName || universityName.includes(u.name))
  if (candidateUniversity) {
    return <UniversityListingBanner candidateUniversity={candidateUniversity} />
  }
  return null
}

const Jobs = () => {
  const { t } = useTranslation("global");
  const [jobs, setJobs] = useState<Job[]>([]);
  const [appliedJobs, setAppliedJobs] = useState<Job[]>([]);
  const [candidateUniversity, setCandidateUniversity] = useState<string | null>(null)
  const [areJobsLoaded, setAreJobsLoaded] = useState(false);
  const [customSearchBarOnFocus, setCustomSearchBarOnFocus] = useState('');
  const [search, setSearch] = useState("");
  const [tabKey, setTabKey] = useState("all-jobs");

  const getData = async () => {
    try {
      let url = wolData.urls.APIUrl + "/jobs?candidate_id=" + localStorage.getItem("candidateId")
      if (search !== "") {
        url = url + "&search=" + search;
        // utils.trackMixpanelEvent('jobs_search_filter', { search_filter: search })
        mixpanel.track('jobs_search_filter', { search_filter: search })
      }
      const response = await fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('jwt')
        }
      })
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        ReactGA.event({ category: 'candidate', action: 'see-job-listing' });
        setJobs(data.jobs)
        setAppliedJobs(data.applied_jobs)
        if (data.candidate_university) {
          setCandidateUniversity(data.candidate_university)
        }
        setAreJobsLoaded(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => { getData() }, []);

  if (!(areJobsLoaded)) { return <WolSpinner /> }

  return (
    <Container className='wol-container'>
      <Row className="mb-4">
        <Col>
          <h2>
            <small style={{ marginRight: '.5em' }}>
              <ReactCountryFlag
                style={{ fontSize: '1em', paddingBottom: '.25em' }}
                countryCode={localStorage.getItem('userSite') || ''}
                svg
              />
            </small>
            {t('jobs.title')}
          </h2>
        </Col>
      </Row>

      {universityMessage(candidateUniversity)}

      <Row>
        <Col>
          <Tabs
            id="jobs-list-tab"
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k as string)}
            className="mb-3"
          >
            <Tab eventKey="all-jobs" title={`${t('jobs.tabAllJobsText')} (${jobs.length})`}>
              <Form
                style={{ fontSize: '12px' }}
                onSubmit={(e) => {
                  e.preventDefault()
                  getData()
                }}
              >
                <Row>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    xs={{ span: 8, offset: 2 }}
                    className='d-flex align-items-center justify-content-center'
                  >

                    <InputGroup
                      className={`custom-search-input ${customSearchBarOnFocus}`}
                    >
                      <Form.Control
                        name='search'
                        className={'search-input'}
                        placeholder={"Buscar"}
                        type='text'
                        onBlur={() => setCustomSearchBarOnFocus('')}
                        onFocus={() => setCustomSearchBarOnFocus('custom-search-focus')}
                        onChange={event => setSearch(event.target.value)}
                        value={search}
                        size='lg'
                      />
                      <Button className='search-button' variant='primmary' type='submit'>
                        <BsSearch />
                      </Button>
                    </InputGroup>
                  </Col>


                </Row>
              </Form>

              {jobs.map(job => <ListItemJob job={job} key={job.id} />)}
            </Tab>
            <Tab eventKey="applied-jobs" title={`${t('jobs.tabAppliedJobsText')} (${appliedJobs.length})`}>
              {appliedJobs.map(job => <ListItemJob job={job} key={job.id} />)}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )

}

export default Jobs;
