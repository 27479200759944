import React from 'react';
import Select from 'react-select'

class MultiInput extends React.Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.name, true);
  };

  render() {
    return (
      <Select
        id={this.props.name}
        options={this.props.options}
        isMulti
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.props.value}
        placeholder={""}
        className={this.props.className}
        isOptionDisabled={() => this.props.value && this.props.value.length > 3}
      />
    );
  }
}
export default MultiInput;
