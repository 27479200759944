import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select'
import ReactCountryFlag from "react-country-flag"

import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { BsEye, BsEyeSlash } from "react-icons/bs";

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import WolFormAlert from '../commons/WoLFormAlert';
import { UniversitySignInBanner } from '../commons/UniversityBanner';

const siteOptions = [
  { value: '', label: '' },
  { value: 'CL', label: <React.Fragment><ReactCountryFlag countryCode="CL" svg /><span style={{ padding: '0 0 0 15px' }}>Chile</span></React.Fragment> },
  { value: 'CO', label: <React.Fragment><ReactCountryFlag countryCode="CO" svg /><span style={{ padding: '0 0 0 15px' }}>Colombia</span></React.Fragment> },
  { value: 'MX', label: <React.Fragment><ReactCountryFlag countryCode="MX" svg /><span style={{ padding: '0 0 0 15px' }}>México</span></React.Fragment> }
]

const formInitialValues = {
  site: '',
  surname: '',
  name: '',
  email: '',
  password: '',
  not_available_to_receive_job_offers: false,
  confirm_password: ''
}

const validationSchema = Yup.object().shape({
  site: Yup.string().required('Ingrese país'),
  surname: Yup.string().required('Ingrese apellido paterno'),
  name: Yup.string().required('Ingrese nombre'),
  email: Yup.string().email('Ingrese un email válido').required('Ingrese email'),
  password: Yup.string().required('Ingrese contraseña').min(8, 'Ingrese a lo menos 8 caracteres').matches(/[0-9]+/, 'Debe incluir un carácter numérico'),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], "Contraseña no coincide").required("Confirme su contraseña")
});

const getSuccessRegistrationPath = (openToHireId, email) => {
  let successUrl = '/sign-in?registration_status=to_confirm&candidate_email=' + email;
  if (openToHireId && openToHireId !== 'null' && openToHireId !== 'undefined') {
    successUrl = successUrl + '&open_to_hire_id=' + openToHireId;
  }
  return successUrl;
}

const displayUniversityBanner = (universityCode) => {
  if (universityCode) {
    const candidateUniversity = wolData['activeUniversities'].find(u => u.code === universityCode)
    if (candidateUniversity) {
      return <UniversitySignInBanner candidateUniversity={candidateUniversity} />;
    }
  }
}

const RegistrationForm = ({ openToHireId, universityCode }) => {
  const history = useHistory();
  const recaptchaRef = React.createRef();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [customPasswordOnFocus, setCustomPasswordOnFocus] = useState('');
  const [customConfirmPasswordOnFocus, setCustomConfirmPasswordOnFocus] = useState('');

  const candidateRegistrationFormOnSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
    try {
      setSubmitting(true);
      const token = await recaptchaRef.current.executeAsync();

      if (!token) {
        setSubmitting(false);
        return;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          candidate: {
            surname: values.surname,
            name: values.name,
            not_available_to_receive_job_offers: values.not_available_to_receive_job_offers
          },
          user: {
            site: values.site,
            email: values.email,
            password: values.password
          }
        })
      };

      const response = await fetch(wolData.urls.APIUrl + '/candidates', requestOptions)
      const data = await response.json()
      if (data.errors) {
        if (data.errors.email) {
          setStatus({ email: data.errors.email })
        }
        if (data.errors.password) {
          setStatus({ password: data.errors.password })
        }
        if (data.errors.surname) {
          setStatus({ surname: data.errors.surname })
        }
        if (data.errors.site) {
          setStatus({ site: data.errors.site })
        }
        if (data.errors.name) {
          setStatus({ name: data.errors.name })
        }

      } else {

        // GA Tracking: Candidate Registration
        ReactGA.event({
          category: 'candidate',
          action: 'registration'
        });
        setSubmitting(false);
        mixpanel.track('Registration successfull');
        history.push(getSuccessRegistrationPath(openToHireId, values.email));
      }

    } catch (error) {
      setSubmitting(false);
      console.error(error)
    }

    setSubmitting(false);
  }

  return (
    <Container id='registration-form-container' className='wol-container pb-4 mb-4'>

      <Row className='container-center mx-auto text-center'>
        <Col>
          <h2>Registro de abogados</h2>
        </Col>
      </Row>

      <Row className='container-center mx-auto text-center text-primary'>
        <Col style={{ fontSize: '19px' }}>
          Accede a los mejores trabajos e información exclusiva de sueldos del mercado legal
        </Col>
      </Row>

      {displayUniversityBanner(universityCode)}

      <Row className='mt-4'>
        <Col>
          <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={candidateRegistrationFormOnSubmit}
          >
            {({ values,
              errors,
              status,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting }) => (

              <Form onSubmit={handleSubmit} className='wol-form container-center mx-auto rounded'>

                <div style={{ fontSize: '19px', fontWeight: 600 }} className='text-center mb-4'>
                  Crea tu cuenta en menos de 1 minuto
                </div>

                <Form.Group>
                  <Form.Label>País *</Form.Label>
                  <Select
                    id='site'
                    name='site'
                    value={values.site.value}
                    onChange={(option) => setFieldValue('site', option.value)}
                    onBlur={() => setFieldTouched('site', true)}
                    placeholder='Seleccione país'
                    className={utils.fieldClassName('site', status, touched, errors)}
                    options={siteOptions}
                  />
                  {utils.displayFieldError('site', status, touched, errors)}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Nombre *</Form.Label>
                  <Form.Control
                    name='name'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    size='lg'
                    className={utils.fieldClassName('name', status, touched, errors)}
                  />
                  {utils.displayFieldError('name', status, touched, errors)}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Apellido *</Form.Label>
                  <Form.Control
                    name='surname'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.surname}
                    size='lg'
                    className={utils.fieldClassName('surname', status, touched, errors)}
                  />
                  {utils.displayFieldError('surname', status, touched, errors)}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    name='email'
                    type='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    size='lg'
                    placeholder='correo@ejemplo.com'
                    className={utils.fieldClassName('email', status, touched, errors)}
                  />
                  {utils.displayFieldError('email', status, touched, errors)}
                </Form.Group>

                <Form.Group >
                  <Form.Label>
                    Contraseña *
                    <br></br><small>Al menos 8 caracteres</small>
                  </Form.Label>
                  <InputGroup
                    className={`custom-password-input ${utils.fieldClassName('password', status, touched, errors)} ${customPasswordOnFocus}`}
                  >
                    <Form.Control
                      name='password'
                      className={showPassword ? 'password-show' : 'password-hide'}
                      type='text'
                      onChange={handleChange}
                      autoComplete='off'
                      onBlur={(e) => {
                        setCustomPasswordOnFocus('')
                        handleBlur(e)
                      }}
                      onFocus={() => setCustomPasswordOnFocus('custom-password-focus')}
                      value={values.password}
                      size='lg'
                    />
                    <Button className='password-button' onClick={() => setShowPassword(!showPassword)} variant='primmary'>
                      {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </Button>
                  </InputGroup>
                  {utils.displayFieldError('password', status, touched, errors)}

                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Confirmación de contraseña *
                  </Form.Label>
                  <InputGroup
                    className={`custom-password-input ${utils.fieldClassName('confirm_password', status, touched, errors)} ${customConfirmPasswordOnFocus}`}
                  >
                    <Form.Control
                      name='confirm_password'
                      className={showConfirmPassword ? 'password-show' : 'password-hide'}
                      type='text'
                      onChange={handleChange}
                      autoComplete='off'
                      onBlur={(e) => {
                        setCustomConfirmPasswordOnFocus('')
                        handleBlur(e)
                      }}
                      onFocus={() => setCustomConfirmPasswordOnFocus('custom-password-focus')}
                      value={values.confirm_password}
                      size='lg'
                    />
                    <Button className='password-button' onClick={() => setShowConfirmPassword(!showConfirmPassword)} variant='primmary'>
                      {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                    </Button>
                  </InputGroup>
                  {utils.displayFieldError('confirm_password', status, touched, errors)}
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    No quiero recibir alertas del mercado legal
                    <br></br><small>Solo te contactaremos por trabajos que calcen con tu perfil</small>
                  </Form.Label>
                  <Form.Check
                    name='not_available_to_receive_job_offers'
                    type='checkbox'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.not_available_to_receive_job_offers}
                  />
                </Form.Group>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Lec2FcaAAAAAFGpPSOOAHVLMJTHXV2b0PQKCSPe"
                />

                <WolFormAlert
                  status={status}
                  errors={errors}
                  errorMessageKey={'candidateRegistrationFormError'}
                  successMessageKey={'candidateRegistrationFormSuccess'}
                />

                <Row className='mt-4'>
                  <Col>
                    <Button className='btn-block' size='lg' variant='danger' type='submit' disabled={isSubmitting}>
                      Crear cuenta
                    </Button>
                  </Col>
                </Row>

                <Row className='mt-5 mb-5 text-center'>
                  <Col>
                    <a style={{ fontSize: '19px' }} href={wolData.urls.frontUrl + '/sign-in'}>Iniciar sesión</a>
                  </Col>
                </Row>
              </Form>
            )
            }
          </Formik>
        </Col>
      </Row>

    </Container>
  )
}

export default RegistrationForm;
