import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap'
import { BsFillRecordFill } from "react-icons/bs";
import { IconContext } from "react-icons";

import wolData from '../../helpers/wolData';

type Job = {
  id: number;
  site: string;
  state: string;
  title: string;
  subtitle: string;
  requirements: string[];
  salary: null | string;
  featured: boolean;
  hide_on_homepage: boolean;
  url: string;
  open_to_hire: null;
  content: string;
  city: string;
  employer: Employer;
  application_state: string;
  external_url: null;
  original_job_to_apply_url: null;
}

type Employer = {
  name: string;
  logo_height: string;
  logo_width: string;
  website_url: string;
  logo_url: string;
  description: string;
  landing_url: string;
}

const displayOfferButton = (job: Job, jobUrl: string) => {
  if (job.state === 'published') {
    return <a href={wolData.urls.homepageUrl + "/" + jobUrl} target="_blank" rel="noreferrer" role="button" className="btn btn-link">Ver oferta</a>;
  } else {
    return null;
  }
}

const displayJobTitle = (job: Job, jobUrl: string) => {
  if (job.state === 'published') {
    return (
      <React.Fragment>
        <IconContext.Provider value={{ color: '#00A364', size: '18px' }}>
          <span><BsFillRecordFill /></span>
        </IconContext.Provider>&nbsp;
        <a style={{ color: getColorByJobState(job) }} href={wolData.urls.homepageUrl + "/" + jobUrl}>{job.title}</a>
      </React.Fragment>
    )
  } else {
    return job.title;
  }
}

const getColorByJobState = (job: Job) => {
  if (job.state === 'published') {
    return '#1F2A66';
  } else {
    return '#666666'
  }
}

const getCompletedButtonText = (job: Job) => {
  if (job.state === 'published') {
    return 'Ver postulación';
  } else {
    return 'Ver información del proceso'
  }
}

const displayJobClosedBadge = (job: Job) => {
  if (job === null) { return null; }
  if (job.state === 'closed') {
    return <React.Fragment>
      <Badge pill variant='secondary'>Oferta cerrada</Badge><br></br>
    </React.Fragment>;

  } else {
    return null;
  }
}

const ListItemJob = ({ job }: { job: Job }) => {
  let jobUrl = job.url;
  if (job.original_job_to_apply_url) {
    jobUrl = job.original_job_to_apply_url;
  }

  let applicationUrl = "/apply-to?job_id=" + job.id + "&candidate_id=" + localStorage.getItem("candidateId");
  if (job.external_url) {
    applicationUrl = job.external_url;
  }

  if (['published', 'closed'].includes(job.state)) {
    return (
      <Row
        className="align-items-center mh-230"
        style={{
          borderBottom: '1px solid #E5E5E5',
          paddingBottom: '10px',
          marginTop: '10px',
          marginBottom: '50px',
          height: '230px'
        }}
      >
        {/* Employer logo */}
        <Col md={3} xs={4} className='d-flex align-items-center justify-content-center'>
          <img
            alt={'employer logo'}
            className='job-images'
            width={job.employer.logo_width}
            height={job.employer.logo_height}
            src={job.employer.logo_url}>
          </img>
        </Col>

        {/* Employer name and job title*/}
        <Col md={6} xs={8}>
          <div><a style={{ color: getColorByJobState(job) }} href={job.employer.website_url}>{job.employer.name}</a></div>
          <div className="job-title-link"
            style={{
              fontSize: '21px',
              fontWeight: 500,
              fontStyle: 'normal',
              color: '#666666'
            }}>{displayJobTitle(job, jobUrl)}</div>
          <div>{job.subtitle} {(job.city) ? ` - ${job.city}` : null}</div>
          {displayJobClosedBadge(job)}
        </Col>

        <Col className={'job-list-item-buttons'}>
          {(job.application_state === 'completed') ?
            <a className={'btn btn-block btn-outline-primary'} href={applicationUrl} >{getCompletedButtonText(job)}</a>
            :
            (job.state !== 'published') ? null :
              (job.application_state === 'created') ?
                <a className='btn btn-outline-danger btn-block' href={applicationUrl}>Completar postulación</a>
                :
                <a className='btn btn-outline-primary btn-block' href={applicationUrl}>Postular</a>
          }
          {displayOfferButton(job, jobUrl)}
        </Col>
      </Row>
    )
  } else {
    return null;
  }
}

export default ListItemJob;