export const workExperienceYearsOptions = [
  {value: '0', label: '0 años'},
  {value: '1', label: '1 años'},
  {value: '2', label: '2 años'},
  {value: '3', label: '3 años'},
  {value: '4', label: '4 años'},
  {value: '5', label: '5 años'},
  {value: '6', label: '6 años'},
  {value: '7', label: '7 años'},
  {value: '8', label: '8 años'},
  {value: '9', label: '9 años'},
  {value: '10', label: '10 años'},
  {value: '11-14', label: '11 a 14 años'}
]

export const englishLevelOptions = [
  {value: '1', label: 'Puede trabajar en inglés'},
  {value: '2', label: 'No puede trabajar en inglés'}
]

export const postgraduateOptions = [
  {value: '1', label: 'LL.M en el extranjero'},
  {value: '2', label: 'Magíster nacional'},
  {value: '3', label: 'Diplomado nacional'}
]

export const getWorkExperienceYearsOptions = (fullExperience?: number | null) => {
  const defaultOptions = workExperienceYearsOptions.slice(0, 2);
  if (!(fullExperience)) { return defaultOptions };
  const currentExperienceYear = Math.floor(fullExperience);
  if (currentExperienceYear === 0) { return defaultOptions };
  if (currentExperienceYear >= 10) { return workExperienceYearsOptions.slice(9, 12) };
  if (!([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(currentExperienceYear))) {
      return defaultOptions
  };

  return workExperienceYearsOptions.slice((currentExperienceYear - 1), (currentExperienceYear + 2));
}