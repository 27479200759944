import { Row, Col, Badge } from 'react-bootstrap';
import { MdCalendarToday } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';

export type Props = {
  applicationData: {
    job: { state: string; },
    application: {
      application_state: string;
      application_date: string | null ;
      application_pretension_of_income: string | null;
    }
  }
}

const InfoIcons = ({ applicationData }: Props) => {
  if (applicationData.job.state !== 'published') { return '' }

  if (applicationData.application.application_state === 'completed') {
    return (
      <Row>
        <Col className='application-state-tag'>
          <Badge pill variant='success'>
            Postulación completa
          </Badge>
          <Badge pill variant='ligth'>
            <MdCalendarToday /><span style={{ marginLeft: '-10' }}>{applicationData.application.application_date}</span>
          </Badge>
          <Badge pill variant='ligth'>
            <FaRegMoneyBillAlt />{applicationData.application.application_pretension_of_income}
          </Badge>
        </Col>
      </Row>
    )

  } else {
    return (
      <Row>
        <Col className='application-state-tag'>
          <Badge pill variant='danger'>
            Postulación incompleta
          </Badge>
        </Col>
      </Row >
    )
  }
}

export default InfoIcons;
