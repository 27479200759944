import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import mixpanel from 'mixpanel-browser';
import utils from '../../helpers/utils';
import wolData from '../../helpers/wolData';
import WorkExperienceIcon from '../commons/WorkExperienceIcon';
import JobsList from './JobsList';
import CustomFlowTitle from '../profile/CustomFlowTitle';

export const handleValidation = (workplaceAux) => {
  const validationObject = {
    position: Yup.string().required("Seleccione un trabajo"),
    workplace_id: Yup.object().shape({ value: Yup.string().required("Ingrese último empleo") }),
    suggested_workplace_name: Yup.string().required("Seleccione un lugar de trabajo"),
    start_date: Yup.date().required("Seleccione una fecha")
      .max(new Date(), "La fecha de inicio debe ser anterior a la fecha actual")
      .test("start_date", "La fecha de inicio no puede ser anterior a 80 años atrás", (startDate) => {
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 80);
        return minDate <= startDate;
      }),
    end_date: Yup.date()
      .max(new Date(), "La fecha de término debe ser anterior a la fecha actual")
      .when("start_date", (start_date, yup) => start_date && yup.min(start_date, "La fecha de término debe ser posterior a la de inicio"))
  }

  if (workplaceAux && workplaceAux.label === "Otro") {
    validationObject['suggested_workplace_name'] = Yup.string().required("Seleccione un lugar de trabajo trabajo")
  }

  return Yup.object().shape(validationObject);
}

const ProfileForm3 = ({ formFlow, displayCreateForm = false }) => {
  const [workplaceAux, setWorkplaceAux] = useState(null);
  const [workplaces, setWorkplaces] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [showCreateForm, setShowCreateForm] = useState(displayCreateForm && displayCreateForm === true);
  const [jobs, setJobs] = useState([]);
  const [workExperienceYear, setWorkExperienceYear] = useState(null);
  const [showJobs, setShowJobs] = useState(false);
  const [firstJobSwitchIsActive, setFirstJobSwitchIsActive] = useState(false);
  const [showMeTheMoneyIsReady, setShowMeTheMoneyIsReady] = useState(null);
  const [showMeTheMoneyConfirmed, setShowMeTheMoneyConfirmed] = useState(null)
  const setFirstJob = async (firstJobState) => {
    const url = wolData.urls.APIUrl + '/candidates/' + window.localStorage.getItem('candidateId') + "/first_job";
    const response = await window.fetch(
      url,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('jwt') },
        body: JSON.stringify({ first_job: firstJobState })
      }
    )
    const data = await response.json()
    if (data.success === true) {
      setFirstJobSwitchIsActive(firstJobState)
    }
  }

  const setConfirmedStatus = () => {
    if (localStorage.getItem('jobHistoryConfirmed') === 'true') {
      setShowMeTheMoneyConfirmed(true)
    } else {
      setShowMeTheMoneyConfirmed(false)
    }
  }

  const confirm = () => {
    localStorage.setItem('jobHistoryConfirmed', 'true');
    setShowMeTheMoneyConfirmed(true);
  }

  const getData = async () => {
    const url = wolData.urls.APIUrl + '/candidates/' + window.localStorage.getItem('candidateId') + "/job_history";
    const response = await window.fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
      }
    })
    const data = await response.json()
    setConfirmedStatus()
    setShowMeTheMoneyIsReady(data.show_me_the_money_is_ready)
    setWorkExperienceYear(data.work_experience_years)
    setJobs(data.jobs)
    setShowJobs(true)
    setFirstJobSwitchIsActive(data.first_job)
    setWorkplaces(await utils.getPastJobs(localStorage.getItem('userSite')))
  }

  useEffect(() => {
    getData();
  }, [refresh])

  const initialValues = {
    position: "",
    workplace_id: 0,
    suggested_workplace_name: "",
    start_date: "",
    end_date: ""
  }

  const candidateProfile3OnSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
      values['workplace_id'] = values['workplace_id'].value

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
        body: JSON.stringify(values)
      };
      const profileFormUrl = wolData.urls.APIUrl + "/candidates/" + localStorage.getItem("candidateId") + "/positions/new";
      const response = await fetch(profileFormUrl, requestOptions)
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      }
      else {
        setShowJobs(prev => !prev)
        setShowCreateForm(prev => !prev)
        setRefresh(prev => prev + 1)
        mixpanel.track('Profile 3 added job succesfully')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProfile3Url = (flow) => {
    if (flow === 'application') {
      return wolData.urls.frontUrl + "/apply-to?job_id=" + localStorage.getItem("applyingToJobId") + "&candidate_id=" + localStorage.getItem("candidateId");
    } else if (flow === 'salary') {
      return wolData.urls.frontUrl + "/show-me-the-money";
    } else {
      return null;
    }
  }

  const getSubmitText = (flow) => {
    if (flow === 'application') {
      return 'Continuar Postulación';
    } else if (flow === 'salary') {
      return 'Show me the money'
    } else {
      return 'Guardar';
    }
  }

  const displaySubmitProfile3 = (flow) => {
    const button = <Button
      style={{ marginTop: '20px', marginBottom: '40px', padding: '10px' }}
      size="lg"
      variant="danger"
      href={getProfile3Url(flow)}
    >
      {getSubmitText(flow)}
    </Button>

    if (flow === 'salary') {
      return (
        <React.Fragment>
          <br></br>
          <Alert variant='warning' style={{ fontSize: '14px' }}>
            Mantener tu <strong>historial de trabajos actualizado</strong> es un factor relevante para obtener resultados personalizados y ajustados a tu perfil.
          </Alert>
          {button}
        </React.Fragment>
      );
    } else if (flow === 'application') {
      return (
        <React.Fragment>
          <br></br>
          <Alert variant="light" style={{ fontSize: '14px' }}>
            Estás postulando a <strong>{localStorage.getItem('applyingToJobTitle')}</strong>
          </Alert>
          {button}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }


  if (formFlow === 'salary' && showMeTheMoneyIsReady !== true && showMeTheMoneyConfirmed !== true) { confirm() }

  return (
    <React.Fragment>
      <CustomFlowTitle formFlow={formFlow} />

      <div className='wol-form'>
        <Row>
          <Col>
            <h2 style={{ fontWeight: 500 }}>Experiencia profesional <WorkExperienceIcon experienceYears={workExperienceYear} /></h2>
          </Col>
        </Row>

        <Row className='mt-4 mb-2'>
          <Col>
            <Form.Check
              type="switch"
              id="first-job-switch"
              label="Busco mi primer trabajo"
              checked={firstJobSwitchIsActive}
              onChange={(e) => {
                if (e.target.checked) {
                  setFirstJob(true)
                } else {
                  setFirstJob(false)
                }
              }}
            />
          </Col>
          <Col>
            <Button className='float-right' variant="danger" onClick={() => setShowCreateForm(prev => !prev)}>
              {showCreateForm ? 'Cancelar' : 'Agregar trabajo'}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            {showCreateForm ? (
              <Formik
                initialValues={initialValues}
                validationSchema={handleValidation(workplaceAux)}
                onSubmit={candidateProfile3OnSubmit}
              >
                {({ values,
                  errors,
                  status,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                  handleBlur,
                  handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit} style={{ backgroundColor: '#E0E8FD', padding: '15px' }}>
                      <Form.Group className="form-group-custom" >
                        <Form.Label>Cargo <strong>*</strong></Form.Label>
                        <Form.Control
                          name="position"
                          as="select"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.position}
                          className={utils.fieldClassName('position', status, touched, errors)}
                        >
                          {wolData.positions.map((position) => (
                            <option key={position.value} value={position.value}>
                              {position.label}
                            </option>
                          ))}
                        </Form.Control>
                        {utils.displayFieldError('position', status, touched, errors)}
                      </Form.Group>

                      <Form.Group className="form-group-custom">
                        <Form.Label>Lugar de trabajo *</Form.Label>
                        <Select
                          name="workplace_id"
                          options={workplaces}
                          onBlur={() => { setFieldTouched("workplace_id", true) }}
                          value={values.workplace_id}
                          placeholder={""}
                          className={utils.fieldClassName('workplace_id', status, touched, errors)}
                          onChange={(option) => {
                            setFieldValue("workplace_id", option)
                            setFieldValue("suggested_workplace_name", option.label)
                            setWorkplaceAux(option)
                          }}
                        />
                        {utils.displayFieldError('suggested_workplace_name', status, touched, errors)}
                      </Form.Group>

                      {(values.workplace_id.label === "Otro") ?
                        <Form.Group className="form-group-custom">
                          <Form.Label>Escribe el nombre del lugar de trabajo <strong>*</strong></Form.Label>
                          <Form.Control
                            name="suggested_workplace_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.suggested_workplace_name}
                          />
                        </Form.Group> : null}

                      <Form.Group className="form-group-custom">
                        <Form.Row>
                          <Col>
                            <Form.Label>Fecha de inicio <strong>*</strong></Form.Label>
                            <Form.Control
                              name="start_date"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.start_date}
                              type="date"
                              className={utils.fieldClassName('start_date', status, touched, errors)}
                            >
                            </Form.Control>
                            {utils.displayFieldError('start_date', status, touched, errors)}
                          </Col>
                          <Col>
                            <Form.Label >Fecha de término <strong>*</strong></Form.Label>
                            <Form.Control
                              name="end_date"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.end_date}
                              type="date"
                              className={utils.fieldClassName('end_date', status, touched, errors)}
                            >
                            </Form.Control>
                            <small>Dejar en blanco si aún te encuentras trabajando en esta posición</small>
                            {utils.displayFieldError('end_date', status, touched, errors)}
                          </Col>
                        </Form.Row>
                      </Form.Group>

                      <Button variant="danger" type="submit">Agregar</Button>
                    </Form>
                  )
                }}
              </Formik>) : null}
          </Col>
        </Row>

        {(firstJobSwitchIsActive) ? null : <JobsList jobs={jobs} showJobs={showJobs} setWorkExperienceYear={setWorkExperienceYear} />}
      </div>

      {displaySubmitProfile3(formFlow)}
    </React.Fragment>
  )
}

export default ProfileForm3;
