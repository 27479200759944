import React from 'react';

const CustomFlowTitle = ({ formFlow }) => {
  if(formFlow === 'application'){
    return  <React.Fragment>
              <h2 className='mb-2'>{localStorage.getItem('applyingToJobTitle')}</h2>
              <h5 className='mb-4'>Completa esta sección para enviar tu postulación</h5>
            </React.Fragment>
  }

  if(formFlow === 'salary'){
    return  <React.Fragment>
              <h2 className='mb-2'>Show me the money</h2>
              <h5 className='mb-4'>Completa esta sección y decubre cuánto ganan los abogados con un perfil similar al tuyo y más</h5>
            </React.Fragment>

  }

  return null
}
export default CustomFlowTitle;
