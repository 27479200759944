import React, { useState } from 'react';
import { Row, Col, Form, Table, Image } from 'react-bootstrap';
import Select from 'react-select';
import UniversityTierTooltipCo from './UniversityTierTooltipCo';
import EnglishLevelTooltipCo from './EnglishLevelTooltipCo';
import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import logo from '../../images/logo.svg'

// TODO: Replace by API call
const experienceYearsOptions = [
  {value: 0, label: '0 años de exp.'},
  {value: 1, label: '1 año de exp.'},
  {value: 2, label: '2 a 4 años de exp.'},
  {value: 3, label: '5 a 7 años de exp.'},
  {value: 4, label: '8 a 10 años de exp.'}
]

const universityOptions = [
  {value: 1, label: 'Segmento A'},
  {value: 2, label: 'Segmento B'}
]

const englishLevel = [
  {value: 1, label: 'Segmento A'},
  {value: 2, label: 'Segmento B'}
]

const options = {
  experience_years: experienceYearsOptions,
  university: universityOptions,
  english_level: englishLevel
}

const formatSalary = (salary) => {
  const amount = new Intl.NumberFormat('en').format(salary)
  return `$${amount}`
}

const fetchSalaryGuideProfile = async (url, request) => {
  try {
    const response = await fetch(url, request);
    const data = await response.json();

    return ({
      status: response.status,
      body: data
    })
  } catch (error) {
    console.error(error);
  }
}

const displayTableDate = () => {
  const months = [ 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ];
  const d = new Date();
  const monthName = months[d.getMonth()];
  const year = d.getFullYear();
  return `${monthName} de ${year}`;
}

const displayResults = (results) => {
  return (
    <React.Fragment>

      <Row className='mt-5 mb-4'>
        <Col className='text-center'>
          <span>Sueldo bruto según perfil seleccionado</span>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table className='text-center salary-guide-table' responsive hover>
            <thead>
              <tr style={{ backgroundColor: '#E0E8FD' }}>
                <th className='text-left align-middle' style={{ maxWidth: '220px' }}>
                  <Image src={logo} width={'20%'}/>
                </th>
                <th className='align-middle'></th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td className='text-left'><strong>Cantidad de ocupantes</strong></td>
                <td>{results.total}</td>
              </tr> */}
              <tr>
                <td className='text-left'><strong>Promedio</strong></td>
                <td>{formatSalary(results.average)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className='salary-guide-comments mt-3'>
        <Col>
          - Considera datos hasta <strong>{displayTableDate()}</strong><br />
        </Col>
      </Row>

    </React.Fragment>
  )
}

const ProfileCo = () => {
  const [experienceYears, setExperienceYears] = useState(null);
  const [university, setUniversity] = useState(null);
  const [englishLevel, setEnglishLevel] = useState(null);
  const [results, setResults] = useState(null);

  const updateProfile = async (_experienceYears, _university, _englishLevel) => {
    if (_experienceYears === null) { return null }
    if (_university === null) { return null }
    if (_englishLevel === null) { return null }

    const body = {
      experience_years: _experienceYears.value,
      university: _university.value,
      english_level: _englishLevel.value
    }
    const url = `${wolData.urls.APIUrl}/candidates/show_me_the_money_profile_co`;
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('jwt')}` }
    }

    const res = await fetchSalaryGuideProfile(url, request);
    if (res.status === 200) {
      if (res.body.errors && res.body.errors.general) {
        localStorage.removeItem('jwt');
        window.location.href = wolData.urls.employerFrontUrl + "/sign-in";
      } else {
        setResults(res.body)
      }
    }

    return null
  }

  utils.trackMixpanelEvent('ShowMeTheMoney: Profile CO')

  return (
    <div id='show-me-the-money-container' className='wol-container mb-5'>
      <Row className='mt-5'>
        <Col>
          <h2>Show me the money</h2>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col>
          <p>A continuación podrás averiguar cuál es el sueldo bruto en el mercado colombiano, según el perfil que tú determines.</p>
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col>
          <Form>
            <Row>

              <Col lg='4'>
                <Form.Group>
                  <Form.Label>
                    <strong>Años de experiencia</strong>
                  </Form.Label>
                  <Select
                    placeholder={""}
                    isClearable={true}
                    value={experienceYears}
                    options={options.experience_years}
                    onChange={(option) => {
                      // TODO: Replace by actual type (try {value: string, label: string})
                      // console.log(option)
                      setExperienceYears(option)
                      updateProfile(option, university, englishLevel)
                    }}
                  />
                </Form.Group>
              </Col>

              <Col lg='4'>
                <Form.Group>
                  <Form.Label>
                    <strong>Universidad</strong>&nbsp;&nbsp;
                    <UniversityTierTooltipCo />
                  </Form.Label>
                  <Select
                    placeholder={""}
                    isClearable={true}
                    value={university}
                    options={options.university}
                    onChange={(option) => {
                      // TODO: Replace by actual type (try {value: string, label: string})
                      // console.log(option)
                      setUniversity(option)
                      updateProfile(experienceYears, option, englishLevel)
                    }}
                  />
                </Form.Group>
              </Col>

              <Col lg='4'>
                <Form.Group>
                  <Form.Label>
                    <strong>Nivel de inglés</strong>&nbsp;&nbsp;
                    <EnglishLevelTooltipCo />
                  </Form.Label>
                  <Select
                    placeholder={""}
                    isClearable={true}
                    value={englishLevel}
                    options={options.english_level}
                    onChange={(option) => {
                      // TODO: Replace by actual type (try {value: string, label: string})
                      // console.log(option)
                      setEnglishLevel(option)
                      updateProfile(experienceYears, university, option)
                    }}
                  />
                </Form.Group>
              </Col>

            </Row>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>
          {(results ===null || experienceYears === null || university === null || englishLevel === null) ? null : displayResults(results)}
        </Col>
      </Row>
    </div>
  )
}

export default ProfileCo;
