import { Formik, FormikHelpers } from 'formik';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import session from '../../helpers/session';

type Props = {
  openToHireId: string,
  registrationStatus: string,
  candidateEmail: string
}

type SignInValues = {
  email: string,
  password: string
}

export const getInitialValues = (registrationStatus: string, candidateEmail: string) => {
  // Email input is automatically filled when candidate comes from registration or confirmation flow
  const initialValues = { email: '', password: '' };
  if( registrationStatus && candidateEmail && ['to_confirm', 'confirmed'].includes(registrationStatus) ) {
    return { ...initialValues, ...{email: candidateEmail} };
  } else {
    return initialValues;
  }
}

const SignInForm = ({ openToHireId, registrationStatus, candidateEmail }: Props) => {

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Ingrese email'),
    password: Yup.string().required('Ingrese contraseña'),
  });

  const handleSubmit = async (values: SignInValues, helpers: FormikHelpers<SignInValues>) => {
    const { setSubmitting, setStatus } = helpers

    try {
      setTimeout(() => { setSubmitting(false) }, 500);
      setSubmitting(true);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: values.email, password: values.password })
      };

      const response = await fetch((wolData.urls.APIUrl + "/auth/sign_in"), requestOptions)
      const data = await response.json()
      if (data.errors) {
        if (data.errors.email) { setStatus({ email: data.errors.email }) }
        if (data.errors.password) { setStatus({ password: data.errors.password }) }
        if (data.errors.general) { setStatus({ general: data.errors.general }) }

        ReactGA.event({ category: 'candidate', action: 'failed-login' });
        utils.trackMixpanelEvent('Failed login', {requested_account: values.email})

      } else {
        session.create(data);

        if (openToHireId && openToHireId !== 'null' && openToHireId !== 'undefined') {
          window.location.href = wolData.urls.frontUrl + "/apply-to?job_id=" + openToHireId;
        } else {
          window.location.href = wolData.urls.frontUrl + "/jobs?candidate_id=" + data.current_user.candidate_id;
        }

        setStatus({ successFormSubmission: true });
        ReactGA.event({ category: 'candidate', action: 'successful-login' });
        utils.trackMixpanelEvent('Succesful login', {requested_account: values.email})
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (

    <Formik
      initialValues={getInitialValues(registrationStatus, candidateEmail)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values,
        errors,
        status,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit }) => (

        <Container id='signin-form-container' className='wol-container mb-5'>
          <Row>
            <Col>
              <h2 className='mb-3 text-center'>Inicio de Sesión</h2>
            </Col>
          </Row>

          <Row className='container-center mx-auto text-center'>
            <Col>
              {(status && status.general) ? <Alert variant='danger'>Usuario y/o contraseña inválidos</Alert> : null}

              {(registrationStatus && registrationStatus === 'to_confirm' && candidateEmail) ?
                <Alert variant='success'>
                  Tu cuenta fue creada exitosamente. Ya está todo listo para ingresar a la plataforma con el usuario <strong>{candidateEmail}</strong>.
                </Alert> : null
              }

              {(registrationStatus && registrationStatus === 'confirmed') ?
                <Alert variant='success'>
                  Tu cuenta fue activada exitosamente. Ya puedes iniciar sesión en la plataforma.
                </Alert> : null
              }
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              <Form onSubmit={handleSubmit} className='container-center mx-auto wol-form'>

                <Row>
                  <Col>
                    <Form.Group className='mb-5'>
                      <Form.Label>Email *</Form.Label>
                      <Form.Control
                        name="email"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={touched.email && errors.email ? "error" : undefined}
                      />
                      {touched.email && errors.email ? (<div className="error-message">{errors.email}</div>) : null}
                    </Form.Group>

                    <Form.Group className='mb-5'>
                      <Form.Label>Contraseña *</Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={touched.password && errors.password ? "error" : undefined}
                      />
                      {touched.password && errors.password ? (<div className="error-message">{errors.password}</div>) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col>
                    <Button variant="danger" type="submit" className='btn-block' size='lg' disabled={isSubmitting}>
                      Enviar
                    </Button>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col className='text-center mt-4'>
                    <a href={wolData.urls.frontUrl + "/password-recovery"}>Recuperar contraseña</a>
                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  )
}

export default SignInForm;
