import { Container, Row, Col, Table, Alert, Tabs, Tab } from 'react-bootstrap';
import { Results as ResultsData} from "./SharedTypes";
import EnglishLevelResults from "./EnglishLevelResults";
import generalTableImage from "../../images/candidates.-salaries-general-table.png"
import universityImage from "../../images/university.png"
import universityPerformanceImage from "../../images/university-performance.png"
import englishLevelImage from "../../images/english-level.png"
import jobHistoryImage from "../../images/job-history.png"
import genderImage from "../../images/gender.png"
import postgraduateImage from "../../images/postgraduate.png"
import utils from '../../helpers/utils';
import SalaryCalculator from './SalaryCalculator';
import PosgraduateSalaryCalculator from './PostgraduateSalaryCalculator';

export const previousYear = 2022;

export const getWorkExperienceYearsLabel = (workExperienceYears?: number | null) => {
  if(workExperienceYears){
    return `${workExperienceYears} años`;
  }else{
    return 'Sin experiencia registrada';
  }
}

export const Title = () => { return <h2 className='mb-5'>Show me the money</h2> }

export const WarningMessage = ({ results }: { results?: ResultsData }) => {
  if(!(results?.salary_data?.message)) { return '' }

  return (
    <Container className='mt-4'>
      <Row>
        <Col>
          <Title />
          <Alert variant="warning">{results.salary_data.message}.</Alert>
        </Col>
      </Row>
    </Container>
  )
}

export const universityLabel = (results?: ResultsData) => {
  if (!(results) || !(results.university)) {
    return '';
  }

  if(results.university_ranking && results.university_ranking_students_total){
    return `${results.university} (ranking ${results.university_ranking} de ${results.university_ranking_students_total})`;
  }

  return results.university;
}

export const ProfileTable = ({ results }: { results?: ResultsData} ) => {
  if (!(results)) { return '' };

  return (
    <Row className='mt-4 mb-5'>
      <Col>
        <h5 className='mb-3'><strong>Información entregada</strong></h5>

        <Table responsive className="text-xs-center" style={{ fontSize: '13px' }}>
          <tbody>
            <tr className='table-info'>
              <th style={{ width: '200px' }}>Experiencia profesional</th>
              <td>{getWorkExperienceYearsLabel(results.work_experience_years)}</td>
            </tr>
            <tr className='table-info'>
              <th>Nivel de inglés</th>
              <td>{results.english_level_label}</td>
            </tr>
            <tr className='table-info'>
              <th>Universidad</th>
              <td>{universityLabel(results)}</td>
            </tr>
            <tr className='table-info'>
              <th>Año de egreso</th>
              <td>{results.university_graduation_year_label}</td>
            </tr>
            <tr className='table-info'>
              <th>Fecha de actualización</th>
              <td>{results.issue_date}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export const SalaryTable = ({ results }: { results?: ResultsData}) => {
  if (!(results?.salary_data?.salary)) { return '' };

  return (
    <Row className='mt-4 mb-5'>
      <Col>
        <h5 className='mb-3'><strong>Sueldo líquido</strong></h5>
        <p>Considerando los sueldos registrados en la plataforma hasta el día <b>01-10-2023</b> y tu información actualizada al <b>{results.issue_date}</b>, el sueldo líquido de abogados de tu perfil en Chile es:</p>

        <Table responsive className="text-xs-center" style={{ fontSize: '13px' }}>
          <tbody>
            <tr>
              <th style={{ width: '200px' }}>Piso</th><td>{utils.formatSalary(results.salary_data.salary.p20)}</td>
            </tr>
            <tr>
              <th>Promedio</th><td>{utils.formatSalary(results.salary_data.salary.avg)}</td>
            </tr>
            <tr>
              <th>Techo</th><td>{utils.formatSalary(results.salary_data.salary.p90)}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export const StudentSalaryTable = ({ results }: { results?: ResultsData }) => {
  if(!(results?.salary_data?.students_info)) { return '' }

  const { min, avg, max } = results.salary_data.students_info
  return (
    <Row className='mb-5'>
      <Col>
        <h5 className='mb-3'>Procuradores</h5>
        <p>El sueldo líquido de los procuradores en Chile, actualizado a <strong>octubre de 2023</strong>, es de:</p>

        <Table responsive className="text-xs-center" style={{ fontSize: '13px' }}>
          <tbody>
            <tr>
              <th style={{ width: '200px' }}>Piso</th><td>$ 150.000</td>
            </tr>
            <tr>
              <th>Promedio</th><td>$ 420.950</td>
            </tr>
            <tr>
              <th>Techo</th><td>$ 700.000</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export const EnglishLevelTable = ({ results }: { results?: ResultsData }) => {
  if ((!results?.english_data?.levels)) { return '' };

  return (
    <Row className='mb-5'>
      <Col>
        <h5 className='mb-3'>Nivel de inglés por año de egreso</h5>

        <EnglishLevelResults results={results} />

        <Table responsive className="text-xs-center" style={{ fontSize: '13px' }}>
          <tbody>
            <tr>
              <th style={{ width: '200px' }}>Nativo o Bilingüe</th><td>{results.english_data.levels[0]}</td>
            </tr>
            <tr>
              <th>Fluido</th><td>{results.english_data.levels[1]}</td>
            </tr>
            <tr>
              <th>Avanzado</th><td>{results.english_data.levels[2]}</td>
            </tr>
            <tr>
              <th>Intermedio avanzado</th><td>{results.english_data.levels[3]}</td>
            </tr>
            <tr>
              <th>Intermedio</th><td>{results.english_data.levels[4]}</td>
            </tr>
            <tr>
              <th>Básico</th><td>{results.english_data.levels[5]}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export const canAccessToSalaryCalculatorTab = (results?: ResultsData | null) => {
  if (
    !(results) ||
    !(results.work_experience_years) ||
    !(results.salary_data) ||
    !(results.salary_data.salary) ||
    !(results.salary_data.salary.cluster)

  ) { return false };

  if(results.work_experience_years >= 11){ return false };

  if(['high_profile', 'big_law'].includes(results.salary_data.salary.cluster)){ return false };

  return true;
}

const Results = ({ data }: {data: ResultsData}) => {
  if (data?.salary_data?.message) {
    utils.trackMixpanelEvent('ShowMeTheMoney: Results no disclosed');
    return <WarningMessage results={data}/>;

  } else {
      utils.trackMixpanelEvent('ShowMeTheMoney: Results successfuly disclosed');

    return (
      <Container id='show-me-the-money-container' className='wol-container mb-5'>
        <Title />

        <Tabs id="show-me-the-money-tabs" onSelect={ (key) => utils.trackMixpanelEvent(`ShowMeTheMoney: Tab ${key} view`) } >
          <Tab eventKey="personal" title="Tu sueldo">
            <ProfileTable results={data} />
            <SalaryTable results={data} />
            <StudentSalaryTable results={data} />
            <EnglishLevelTable results={data} />
          </Tab>

          <Tab eventKey="general" title="Sueldos en Chile">
            <Row className='mb-4'>
              <Col className="text-justify">
                <h5 className='mt-4 mb-4'>Sueldos en general en Chile</h5>
                <img src={generalTableImage} width="65%" alt='general-table'></img>
              </Col>
            </Row>
          </Tab>

          {
            (canAccessToSalaryCalculatorTab(data)) ?
              <Tab eventKey="calculator" title="Calculadora: Inglés">
                <SalaryCalculator results={data} />
              </Tab> : ''
          }

          {
            (canAccessToSalaryCalculatorTab(data)) ?
              <Tab eventKey="postgraduate-calculator" title="Calculadora: Posgrado">
                <PosgraduateSalaryCalculator results={data} />
              </Tab> : ''
          }

          <Tab eventKey='more' title='Quiero saber más'>
            {/* 1 */}
            <Row className='mt-4'>
              <Col className='text-justify'>
                <h5>Transparencia y confidencialidad</h5>
                <p>La información que cada persona aporta a Work On Law la retroalimentamos de manera agregada a nuestros usuarios. Siguiendo estrictos métodos de confidencialidad de los datos y usando algoritmos diseñados por ingenieros y abogados, ponemos a tu disposición, de manera gratuita, información de sueldos constantemente actualizada. Tu colaboración es esencial para lograr mayor transparencia en el tema de las remuneraciones.</p>
              </Col>
            </Row>

            {/* 2 */}
            <Row className='mt-4'>
              <Col className='text-justify'>
                <h5>¿Cómo calculamos tu sueldo?</h5>
                <p>Son varios los factores que la fórmula de Work On Law considera para entregarte tu sueldo de mercado. Un factor que ha cambiado en los últimos 10 años, y que desgraciadamente no ha desaparecido, es la brecha de género, tema que desarrollamos más abajo. Los factores que utiliza Work On Law para calcular tu sueldo de mercado son:</p>

                <ul style={{ fontStyle: 'italic' }}>
                  <li>Universidad de egreso</li>
                  <li>Ranking de egreso</li>
                  <li>Nivel de inglés</li>
                  <li>Posgrados</li>
                  <li>Género</li>
                  <li>Ciudad</li>
                  <li>Posición o rol</li>
                  <li>A partir del año de experiencia profesional: consideramos tus empleos previos o historial laboral</li>
                </ul>

                <p>A continuación analizaremos cada uno de los factores por separado. Para facilitar la comprensión, hemos preparado gráficos que muestran cómo influye cada factor en el cálculo de tu sueldo según tus años de experiencia profesional.</p>
              </Col>
            </Row>

            {/* 3 */}
            <Row className='mt-4'>
              <Col md={12} className='text-justify'>
                <h5>Universidad y ranking de egreso</h5>
                <p>Tienen gran importancia en los primeros años profesionales, puesto que son los criterios que utilizan muchos empleadores a la hora de contratar a recién egresados o abogados sin experiencia.</p>
              </Col>
              <Col xs={12} md={5} ><img src={universityImage} width="80%" alt='university-graph'></img></Col>
              <Col xs={12} md={5} ><img src={universityPerformanceImage} width="80%" alt='ranking-graph'></img></Col>
            </Row>

            {/* 4 */}
            <Row className='mt-4'>
              <Col md={12} className='text-justify'>
                <h5>Nivel de inglés</h5>
                <p>A diferencia de otros factores que pueden tener un impacto más significativo en los primeros años de la carrera profesional, el nivel de inglés mantiene su importancia a lo largo de toda la trayectoria laboral. Aunque en el gráfico puede parecer que la valoración del dominio del inglés disminuye con el tiempo, esto se debe en realidad al efecto que tienen los posgrados en el extranjero. Es importante destacar que uno de los beneficios de cursar un LL.M en un país anglosajón es precisamente mejorar el nivel de inglés. Por lo tanto, parte del factor posgrado se superpone con el factor inglés, lo que puede explicar la aparente disminución de la valoración del inglés en el gráfico. En resumen, el nivel de inglés es un factor clave en la carrera profesional y su importancia se mantiene a lo largo del tiempo.</p>
              </Col>
              <Col xs={12} md={5} ><img src={englishLevelImage} width="80%" alt='english-graph'></img></Col>
            </Row>

            {/* 5 */}
            <Row className='mt-4'>
              <Col md={12} className='text-justify'>
                <h5>Ciudad</h5>
                <p>El impacto de la ciudad también acompaña como factor durante toda la carrera porque la mayoría de los empleadores que pagan sueldos altos se concentran en Santiago. En regiones, Puerto Varas, Viña del Mar y Rancagua presentan algunos casos de empleadores cuyas remuneraciones compiten con sueldos de mercado. Aunque pueda parecer lo contrario, los sueldos pagados en la industria minera no alteran el factor de ciudad de residencia,  ya que la mayoría de esos cargos están basados en Santiago.</p>
              </Col>
              <Col md={12} className='text-justify'>
                <h5>Historial de empleos</h5>
                <p>La experiencia profesional es el factor más complejo a la hora de analizar qué cosas impactan en el sueldo de un abogado o abogada. También conocido como “experiencia” de forma genérica, su impacto lo contabilizamos a partir del primer año de trayectoria profesional. Un estudio jurídico de prestigio, una empresa reconocida, o ciertos organismos del Estado, pueden contribuir a adquirir herramientas que el mercado valora.</p>
              </Col>
            </Row>

            {/* 6 */}
            <Row className='mt-4'>
              <Col xs={12} md={5} ><img src={jobHistoryImage} width="80%" alt='job-history-graph'></img></Col>
              <Col xs={12} md={5} ><img src={postgraduateImage} width="80%" alt='postgraduate-graph'></img></Col>
            </Row>

            {/* 7 */}
            <Row className='mt-4'>
              <Col md={12} className='text-justify'>
                <h5>Brecha de género</h5>
                <p>Los hombres siguen ganando más que las mujeres, sólo que el impacto de este factor se ha retrasado. El impacto más pronunciado es a partir de los 10 años de ejercicio profesional.</p>
              </Col>
              <Col xs={12} md={5} >
                <img src={genderImage} width="80%" alt='gender-graph'></img>
              </Col>
            </Row>

            {/* 8 */}
            <Row className='mb-3'>
              <Col xs='12' className='text-justify'>
                <h5>Techo para abogados con más de 13 años</h5>
              </Col>
              <Col xs='12'>
                <p className="text-justify">Por último, un tema que todo abogado(a) se ha preguntado: ¿Hay que ser socio(a) o fiscal de una empresa para dar el gran salto remuneracional?: La respuesta rápida es sin duda Sí.</p>
                <p className="text-justify">A partir de los 10 años de experiencia el factor “rol” o “cargo” es el que mayor impacto produce por sí solo. Así, cargos de primera línea en grandes empresas y estudios jurídicos de alta facturación ganan hasta el doble de los que les siguen en la segunda línea. Es así como dichos socios, gerentes legales (CLO’s o fiscales) pueden llegar a techos que rondan los 25 millones líquidos, doblando el sueldo de la segunda línea.</p>
              </Col>
            </Row>

          </Tab>
        </Tabs>
      </Container>
    )
  }
}

export default Results;
