import { Alert } from 'react-bootstrap'

const WolFormAlert = (props) => {
  const alertTemplates = {
    candidateRegistrationFormError: <Alert variant='danger' style={{ marginTop: '15px' }}>Por favor revisa la información ingresada.</Alert>,
    candidateRegistrationFormSuccess: <Alert variant='success' style={{ marginTop: '15px' }}>Perfil creado exitosamente, para postular solo debes iniciar sesión y completar tu perfil.</Alert>,
    candidateProfileError: <Alert variant='danger' style={{ marginTop: '15px' }}>Por favor revisa la información ingresada.</Alert>,
    candidateProfileSuccess: <Alert variant='success' style={{ marginTop: '15px' }}>Perfil guardado exitosamente.</Alert>
  }

  if (props.errors && Object.keys(props.errors).length > 0) {
    return (alertTemplates[props.errorMessageKey])

  } else if (props.status && props.status.successFormSubmission) {
    return (alertTemplates[props.successMessageKey])

  } else {
    return null;
  }
}

export default WolFormAlert;