import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import WolSpinner from '../commons/WolSpinner';
import InfoIcons from './InfoIcons';
import Stats from './Stats';
import ApplicationStepCheckbox from './ApplicationStepCheckbox';
import ConsentFields from './ConsentFields';

export const consentText = 'Autorizo el uso de mi información personal para efectos de las postulaciones realizadas en la plataforma Work On Law *';

export const Title = ({ applicationData }) => {
  const jobUrl = wolData.urls.homepageUrl + "/" + applicationData.job.url;
  return (
    <Row>
      <Col>
        <h2>
          <a href={jobUrl} target="_blank">{applicationData.job.title}</a>
        </h2>
        <h4>
          {applicationData.job.subtitle}
          {(applicationData.job.city) ? `, ${applicationData.job.city}` : null}
        </h4>
      </Col>
    </Row>
  )
}

export const getInitialValues = (applicationData) => {
  // personal_information_consent is used only with Baker CO
  return {
    pretension_of_income: '',
    application_id: applicationData.application.application_id,
    consent: applicationData.candidate.consent,
    personal_information_consent: false
  }
}

export const stepIsReady = (step, data) => {
  switch (step) {
    case 1: return data.application.profile_1;
    case 2: return data.application.profile_2;
    case 3: return data.application.profile_3;
    case 4: return (data.application.application_state === 'completed');
    case 5: return (data.application.email_is_confirmed);
    default: return false;
  }
}

export const getValidationSchema = (applicationData, pretensionOfIncomeMessage) => {
  const validationObject = { pretension_of_income: Yup.string().required(pretensionOfIncomeMessage) };
  if (applicationData.candidate.consent !== true) {
    validationObject['consent'] = Yup.bool().oneOf([true], 'Autorización requerida');
  }
  // if (applicationData.job.employer_name === 'Baker & McKenzie Colombia') {
  //   validationObject['personal_information_consent'] = Yup.bool().oneOf([true], 'Autorización requerida');
  // }
  return Yup.object().shape(validationObject);
}

export const completeApplicationBody = (formValues) => {
  const body = {
    application_id: formValues.application_id,
    pretension_of_income: formValues.pretension_of_income,
    // personal_information_consent: formValues.personal_information_consent,
    state: 'completed'
  }

  if (formValues.consent === true) {
    return { ...body, ...{ consented_term: consentText } }
  } else {
    return body;
  }
}

const Application = (props) => {
  const { t } = useTranslation("global");
  const jobId = props.jobId;
  const [applicationData, setApplicationData] = useState(null);
  const [isApplicationDataLoaded, setIsApplicationDataLoaded] = useState(false);
  const [emailIsConfirmed, setEmailIsConfirmed] = useState(true);
  const [confirmationEmailRequired, setConfirmationEmailRequired] = useState(false);

  const getData = async () => {
    try {
      const response = await fetch(
        (wolData.urls.APIUrl + "/job_to_apply?job_id=" + jobId),
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') } }
      )
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        ReactGA.event({ category: 'candidate', action: 'start-application' });
        localStorage.setItem("applyingToJobId", jobId);
        localStorage.setItem("applyingToJobTitle", data.job.title);
        setApplicationData(data)
        setIsApplicationDataLoaded(true)
        setEmailIsConfirmed(data.application.email_is_confirmed)
        mixpanel.track('Application view')
      }
    } catch (error) {
      console.log(error);
    }
  }

  const completeApplication = async (values, { setSubmitting, resetForm, setStatus }) => {
    try {
      setSubmitting(true);
      setTimeout(() => { setSubmitting(false) }, 500);

      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
        body: JSON.stringify(completeApplicationBody(values))
      };

      const response = await fetch(wolData.urls.APIUrl + "/complete_application", requestOptions)
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        // GA Tracking: Complete application
        ReactGA.event({ category: 'candidate', action: 'complete-application' });
        mixpanel.track('Application completed')

        // Clean Local Storage
        localStorage.removeItem("applyingToJobId");
        localStorage.removeItem("applyingToJobTitle");
        window.location.href = wolData.urls.frontUrl + "/apply-to?job_id=" + data.job_id;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const askConfirmationEmail = async () => {

    let confirmation = window.confirm(`\n\nTe enviaremos un correo electrónico a ${applicationData.candidate.email} con las instrucciones para confirmar tu cuenta en sólo un paso.\n\n`);
    if(confirmation){
      try {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
          body: JSON.stringify({ candidate_id: applicationData.application.candidate_id, job_id: applicationData.job.id })
        };

        const response = await fetch(wolData.urls.APIUrl + "/candidates/ask_confirmation_email", requestOptions)
        const data = await response.json()
        if (data.errors) {
          if (data.errors.invalid_token) {
            localStorage.removeItem('jwt');
            window.location.href = wolData.urls.frontUrl + "/sign-in";
          }
        } else {
          mixpanel.track('Asked confirmation email')
          setConfirmationEmailRequired(true);
          console.log(data);
        }
      } catch (error) {
        console.log(error);
      }
    }

  }

  useEffect(() => { getData() }, [])

  const confirmationEmailStep = () => {
    if (confirmationEmailRequired) {
      return (
        <Row className='application-steps-content'>
          <Col className='application-steps-message'>
            <Alert variant='warning'>
              Podrás completar tu postulación siguiendo el link de confirmación enviado a tu correo electrónico <strong> {applicationData.candidate.email}</strong>.
            </Alert>
          </Col>
        </Row>
      )
    }

    return (
      <Row className='application-steps-content'>
        <Col md={8} className='application-steps-message'>
          <p className='text-danger'>
            Para continuar es necesario confirmar tu correo electrónico <strong>{applicationData.candidate.email}</strong>. Al enviar una postulación los empleadores podrían estar interesados en contactarse contigo.
          </p>
        </Col>
        <Col md={4}>
          <Button variant='btn btn-outline-danger btn-block application-steps-button' onClick={askConfirmationEmail}>
            Confirmar email
          </Button>
        </Col>
      </Row>
    )
  }

  if (isApplicationDataLoaded === false) { return <WolSpinner /> }

  return (
    <Container id='application-container' className='wol-container mb-5'>
      <Row>
        <Col>

          <Title applicationData={applicationData} />

          <InfoIcons applicationData={applicationData} />

          <Stats applicationData={applicationData} />

          {/* Application steps container */}
          {/* TO DO: Create ApplicationSteps component */}
          {(applicationData.job.state !== 'published') ? null :
            <Row>
              <Col>
                {/* Step 1 */}
                <div className="candidate-application-section">
                  <h4>
                    <ApplicationStepCheckbox active={stepIsReady(1, applicationData)} />
                    Paso 1: Formación Académica
                  </h4>
                  {
                    (stepIsReady(1, applicationData)) ? null :
                      <Row className='application-steps-content'>
                        <Col md={8} className='application-steps-message'><p className="text-danger">Perfil incompleto: Sección Formación Académica pendiente.</p></Col>
                        <Col md={4}><a href="/candidate-profile?form_flow=application" className="btn btn-outline-danger btn-block application-steps-button">Completar</a></Col>
                      </Row>
                  }
                </div>

                {/* Step 2 */}
                <div className="candidate-application-section">
                  <h4>
                    <ApplicationStepCheckbox active={stepIsReady(2, applicationData)} />
                    Paso 2: {t('CandidateProfileForm2.formTitle')}
                  </h4>
                  {
                    (stepIsReady(2, applicationData)) ? null :
                      <Row className='application-steps-content'>
                        <Col md={8} className='application-steps-message'><p className="text-danger">Perfil incompleto: {t('CandidateProfileForm2.formTitle')} pendiente.</p></Col>
                        <Col md={4}><a href="/candidate-profile-2?form_flow=application" className="btn btn-outline-danger btn-block application-steps-button">Completar</a></Col>
                      </Row>
                  }
                </div>

                {/* Step 3 */}
                <div className="candidate-application-section">
                  <h4>
                    <ApplicationStepCheckbox active={stepIsReady(3, applicationData)} textVariant={(stepIsReady(3, applicationData)) ? 'text-success' : 'text-secondary'} />
                    Paso 3: Experiencia profesional ({applicationData.application.experience} años)
                  </h4>
                  {
                    (stepIsReady(3, applicationData)) ?
                      <Row className='application-steps-content'>
                        <Col md={8} className='application-steps-message'><p className="text-secondary">Puedes actualizar esta sección con tus logros, hitos de carrera e industrias donde has trabajado.</p></Col>
                        <Col md={4}><a href="/candidate-profile-3?form_flow=application" className="btn btn-outline-secondary btn-block application-steps-button">Actualizar experiencia</a></Col>
                      </Row>
                      :
                      <Row className='application-steps-content'>
                        <Col md={8} className='application-steps-message'><p className="text-danger">Perfil incompleto: Sección Experiencia Profesional pendiente.</p></Col>
                        <Col md={4}><a href="/candidate-profile-3?form_flow=application" className="btn btn-outline-danger btn-block application-steps-button">Completar</a></Col>
                      </Row>
                  }
                </div>

                {/* Step 4 */}
                <div className="candidate-application-section">
                  <h4>
                    <ApplicationStepCheckbox active={stepIsReady(4, applicationData) && stepIsReady(5, applicationData)} />
                    Paso 4: Postulación
                  </h4>

                  {
                    emailIsConfirmed ? null : confirmationEmailStep()
                  }

                  <Row className='application-steps-content mt-4'>

                    <Col md={8}>
                      {(stepIsReady(1, applicationData) &&
                        stepIsReady(2, applicationData) &&
                        stepIsReady(3, applicationData) &&
                        stepIsReady(5, applicationData)) ?

                        // Should refactor this? And add step 5? Or just add conditional rendering a making an 4.5 step?
                        (stepIsReady(4, applicationData)) ?
                          <p className='text-secondary'>
                            {t('candidateApplication.pretensionOfIncomeLabelCompleted')}: <strong>{applicationData.application.application_pretension_of_income}</strong>
                          </p>
                          :
                          <Formik
                            initialValues={getInitialValues(applicationData)}
                            validationSchema={getValidationSchema(applicationData, t('candidateApplication.errorMessages.pretensionOfIncome'))}
                            onSubmit={completeApplication}>
                            {({ values,
                              errors,
                              status,
                              touched,
                              isSubmitting,
                              handleChange,
                              handleBlur,
                              handleSubmit }) => {

                              return (
                                <Form onSubmit={handleSubmit}>
                                  <div>
                                    {/* Application Id */}
                                    <input
                                      type="hidden"
                                      name="application_id"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.application_id}
                                    />

                                    {/* ¿Cuál es tu pretensión de renta líquida? */}
                                    <Form.Group>
                                      <Form.Label>
                                        {t('candidateApplication.pretensionOfIncomeLabel')} *
                                      </Form.Label>
                                      <Form.Control
                                        name="pretension_of_income"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.pretension_of_income}
                                        as="select"
                                        className={utils.fieldClassName('pretension_of_income', status, touched, errors)}
                                      >
                                        <option value=""></option>
                                        {utils.getSalariesBySite(localStorage.getItem("userSite")).map((s) => (
                                          <option key={s.value} value={s.value}>
                                            {s.label}
                                          </option>
                                        ))}
                                      </Form.Control>
                                      {utils.displayFieldError('pretension_of_income', status, touched, errors)}
                                    </Form.Group>

                                    <ConsentFields
                                      applicationData={applicationData}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      values={values}
                                      status={status}
                                      touched={touched}
                                      errors={errors}
                                    />

                                  </div>
                                  <Button
                                    variant="danger"
                                    type="submit"
                                    size="lg"
                                    disabled={isSubmitting}
                                  >
                                    Postular
                                  </Button>
                                </Form>
                              )
                            }}
                          </Formik>

                        :
                        null
                      }
                    </Col>
                  </Row>
                </div>

              </Col>
            </Row>
          }

        </Col>
      </Row>
    </Container>
  )
}

export default Application;
