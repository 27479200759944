import { Row, Col } from 'react-bootstrap'

type candidateUniversity = {
  code: string;
  name: string;
  registrationMessage: string;
  jobListMessage: string;
  logo: string;
}

export const UniversityListingBanner = ({ candidateUniversity }: { candidateUniversity: candidateUniversity }) => {
  // candidateUniversity['name']
  if (candidateUniversity) {
    return (
      <Row className='mt-4 mb-5' style={{backgroundColor: '#F4F7FF'}}>
        <Col>
          <Row className='mt-3 mb-3 container-center mx-auto'>
            <Col lg={3} xs={12}>
              <img src={candidateUniversity.logo} height={120} alt='university-logo' />
            </Col>
            <Col lg={9} xs={12} className='justify-content-center' style={{ paddingTop: '18px' }}>
              {candidateUniversity['jobListMessage']}
            </Col>
          </Row>
        </Col>
      </Row>

    )
  }
  return null;
}

export const UniversitySignInBanner = ({ candidateUniversity }: { candidateUniversity: candidateUniversity }) => {
  // candidateUniversity['name']
  if (candidateUniversity) {
    return (
      <Row className='container-center mx-auto mt-5 mb-5'>
        <Col>
          <Row>
            <Col className='d-flex justify-content-center'>
              <img src={candidateUniversity.logo} height={120} alt='university-logo' />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col className='d-flex justify-content-center text-justify'>
              {candidateUniversity['jobListMessage']}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  return null
}
