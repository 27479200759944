import { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import mixpanel from 'mixpanel-browser';

import { handleValidation } from '../candidateProfile3/ProfileForm3';
import utils from '../../helpers/utils';
import wolData from '../../helpers/wolData';

const otherWorkplace = (workplace_id) => {
  return (localStorage.getItem('userSite') === 'CL' && workplace_id === 670) ||
    (localStorage.getItem('userSite') === 'CO' && workplace_id === 831) ||
    (localStorage.getItem('userSite') === 'MX' && workplace_id === 837)
}

const EditForm = ({ job, setJob, show, setWorkExperienceYear }) => {
  const [workplaceAux, setWorkplaceAux] = useState(null);
  const [workplaces, setWorkplaces] = useState(null);

  const candidateProfile3OnSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
      }, 500);

      values['workplace_id'] = values['workplace_id'].value
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
        body: JSON.stringify(values)
      };
      const url = wolData.urls.APIUrl + "/candidates/" + localStorage.getItem("candidateId") + "/positions/" + job.id + '/update';
      const response = await fetch(url, requestOptions)
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      }
      else {
        const { created_at, updated_at, ...rest } = data
        setWorkExperienceYear(data.work_experience_years)
        setJob(rest)
        mixpanel.track('Profile 3 edited job succesfully')
        return show();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const { updated_at, created_at, candidate_id, id, ...rest } = job
  const initialWorkplaceLabel = otherWorkplace(job.workplace_id) ? 'Otro' : job.suggested_workplace_name;
  const initialEndDate = job.end_date || undefined;
  const initialValues = { ...rest, workplace_id: { value: job.workplace_id, label: initialWorkplaceLabel }, end_date: initialEndDate }
  useEffect(() => {
    utils.getPastJobs(localStorage.getItem('userSite'))
      .then(res => {
        setWorkplaces(res)
      });
  }, []);

  if (workplaces === null) return null;

  return (
    <Container className='candidate-profile-form-section'>
      <Formik
        initialValues={initialValues}
        validationSchema={handleValidation(workplaceAux)}
        onSubmit={candidateProfile3OnSubmit}
      >
        {({ values,
          errors,
          status,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit }) => {
          return (
            <Container className='edit-form'>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="form-group-custom">
                  <Form.Label>Cargo *</Form.Label>
                  <Form.Control
                    name="position"
                    as="select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.position}
                    className={utils.fieldClassName('position', status, touched, errors)}
                  >
                    {wolData.positions.map((position) => (
                      <option key={position.value} value={position.value}>
                        {position.label}
                      </option>
                    ))}
                  </Form.Control>
                  {utils.displayFieldError('position', status, touched, errors)}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    name="description"
                    as='textarea'
                    maxLength='800'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    className={utils.fieldClassName('description', status, touched, errors)}
                  ></Form.Control>
                  <small style={{ display: 'flex', justifyContent: 'flex-end' }}>{values.description.length}/400</small>
                  {utils.displayFieldError('description', status, touched, errors)}
                </Form.Group>

                <Form.Group className="form-group-custom">
                  <Form.Label>Lugar de trabajo *</Form.Label>
                  <Select
                    name="workplace_id"
                    options={workplaces}
                    onBlur={() => { setFieldTouched("workplace_id", true) }}
                    defaultValue={values.workplace_id}
                    className={utils.fieldClassName('workplace_id', status, touched, errors)}
                    onChange={(option) => {
                      setFieldValue("workplace_id", option)
                      setFieldValue("suggested_workplace_name", option.label)
                      setWorkplaceAux(option)
                    }}
                  />
                  {utils.displayFieldError('workplace_id', status, touched, errors)}
                </Form.Group>

                {otherWorkplace(values.workplace_id.value) ?
                  <Form.Group className="form-group-custom">
                    <Form.Label>Último empleo *</Form.Label>
                    <Form.Control
                      name="suggested_workplace_name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.suggested_workplace_name}
                    />
                  </Form.Group>
                  :
                  null
                }

                <Form.Group className="form-group-custom">
                  <Form.Label>Fecha de inicio *</Form.Label>
                  <Form.Control
                    name="start_date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.start_date}
                    type="date"
                    className={utils.fieldClassName('start_date', status, touched, errors)}
                  >
                  </Form.Control>
                  {utils.displayFieldError('start_date', status, touched, errors)}
                </Form.Group>

                <Form.Group className="form-group-custom">
                  <Form.Label>
                    Fecha de término
                    <small>Este campo debe quedar en blanco si aún te encuentras trabajando en esta posición</small>
                  </Form.Label>
                  <Form.Control
                    name="end_date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.end_date}
                    type="date"
                    className={utils.fieldClassName('end_date', status, touched, errors)}
                  >
                  </Form.Control>
                  {utils.displayFieldError('end_date', status, touched, errors)}
                </Form.Group>

                <div className='edit-form-options'>
                  <Button variant="danger" type="submit">Guardar</Button>
                  <Button variant="primary" onClick={() => show()}>Cancelar</Button>
                </div>
              </Form>
            </Container>
          )
        }}
      </Formik>
    </Container>
  )
}
export default EditForm;
