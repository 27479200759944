import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup';
import bsCustomFileInput from 'bs-custom-file-input'
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import WolFormAlert from '../commons/WoLFormAlert';
import WolSpinner from '../commons/WolSpinner';
import CustomFlowTitle from '../profile/CustomFlowTitle';
import CustomFlowSubmit from '../profile/CustomFlowSubmit';

const currentYear = new Date().getFullYear();
const annualBonusDefaultValues = [
  { value: -1, label: "No aplica" },
  { value: 0, label: "0" },
  { value: 0.25, label: "0.25" },
  { value: 0.50, label: "0.50" },
  { value: 0.75, label: "0.75" },
  { value: 1, label: "1" },
  { value: 1.25, label: "1.25" },
  { value: 1.50, label: "1.50" },
  { value: 1.75, label: "1.75" },
  { value: 2, label: "2" },
  { value: 2.5, label: "2.5" },
  { value: 3, label: "3" },
  { value: 3.5, label: "3.5" },
  { value: 4, label: "4" },
  { value: 4.5, label: "4.5" },
  { value: 5, label: "5" },
  { value: 5.5, label: "5.5" },
  { value: 6, label: "6" },
  { value: 6.5, label: "6.5" },
  { value: 7, label: "7" },
  { value: 7.5, label: "7.5" },
  { value: 8, label: "8 o más" }
]

const getProfile2Url = () => {
  let url = wolData.urls.APIUrl + "/candidates/" + localStorage.getItem('candidateId') + "/get_profile_2";
  if (localStorage.getItem("applyingToJobId")) {
    url = url + '?applying_to_job_id=' + localStorage.getItem("applyingToJobId");
  }
  return url;
}

const getMaxDate = () => {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date
};

const displayLastSalaryShowMeTheMoneyLabel = (flow) => {
  if (flow === 'salary') {
    return <Alert
      variant='warning'
      style={{ marginTop: '10px', fontSize: '14px' }}>
      Es importante actualizar tu <strong>último sueldo</strong>, de esta forma podemos engregarte resultados personalizados y ajustados a tu perfil.
    </Alert>;
  }
  return null;
}

export const getInitialValues = (c, flow) => {
  if (flow === 'salary' && c.last_net_salary_expired && c.last_net_salary_expired === true) {
    c.last_net_salary = "";
  }
  return c;
}

const ProfileForm2 = (props) => {
  const { t } = useTranslation("global");

  const [candidate, setCandidate] = useState(null);
  const [cvInitialValues, setCvInitialValues] = useState(null);
  const [isCandidateLoaded, setIsCandidateLoaded] = useState(false);
  const [fields_default_values, setFieldDefaultValues] = useState(null);
  //TO DO: Check if profile2Status is already needed
  const [profile2Status, setProfile2Status] = useState(null);
  const formFlow = props.formFlow

  useEffect(() => {
    bsCustomFileInput.init();

    fetch(
      getProfile2Url(),
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') } }

    ).then((response) => {
      return response.json()

    }).then((data) => {
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        setProfile2Status(data.profile_2_status)
        setCandidate(data.initial_values)
        setCvInitialValues(data.cv_initial_values)
        setFieldDefaultValues(data.fields_default_values)
        setIsCandidateLoaded(true)
      }
    }).catch((errors) => {
      console.log(errors)
    })
  }, []);

  const getValidationSchema = (userSite, flow) => {
    const validationObject = {
      surname: Yup.string().required(t('CandidateProfileForm2.errorMessages.surname')),
      second_surname: Yup.string().required(t('CandidateProfileForm2.errorMessages.secondSurname')),
      name: Yup.string().required("Ingrese nombre"),
      phone_number: Yup.string().required(t('CandidateProfileForm2.errorMessages.phoneNumber')),
      driver_id: Yup.string().required("Ingrese motivo de búsqueda"),
      city_id: Yup.string().required("Ingrese ciudad"),
      pretension_of_income_since: Yup.string().required(t('CandidateProfileForm2.errorMessages.pretensionOfIncomeSinceLabel')),
      last_net_salary: Yup.string().required("Ingrese último sueldo"),
      birthdate: Yup.date().required("Ingrese fecha de nacimiento").max(getMaxDate(), "Fecha de nacimiento inválida (edad debe ser mayor o igual a 18 años)"),
      gender: Yup.string().required("Ingrese género")
    }

    if (userSite === 'CL' && flow === 'salary') {
      validationObject['bonus_salary'] = Yup.string().required('Ingrese sueldos de bono')
    }

    if (userSite !== 'MX') {
      validationObject['dni'] = Yup.string().required(t('CandidateProfileForm2.errorMessages.dni'))
    }

    if (cvInitialValues.url === null) {
      validationObject['cv'] = Yup.mixed().required(t('CandidateProfileForm2.errorMessages.cv'))
    }

    return Yup.object().shape(validationObject);
  }

  const candidateProfileForm2OnSubmit = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);

    let data = new FormData();

    data.append("surname", values.surname)
    data.append("second_surname", values.second_surname)
    data.append("name", values.name)
    data.append("phone_number", values.phone_number)
    data.append("driver_id", values.driver_id)
    data.append("pretension_of_income_since", values.pretension_of_income_since)
    data.append("last_net_salary", values.last_net_salary)
    data.append("bonus_salary", values.bonus_salary)
    data.append("linkedin_url", values.linkedin_url)
    data.append("birthdate", values.birthdate)
    data.append("dni", values.dni)
    data.append("gender", values.gender)
    data.append("city_id", values.city_id)

    if (values.cd_id) { data.append("cd_id", values.cd_id) }
    if (values.cv && values.cv.name) { data.append("cv", values.cv) }

    const requestOptions = {
      method: 'put',
      headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
      body: data
    };

    const profileForm2Url = wolData.urls.APIUrl + "/candidates/" + localStorage.getItem("candidateId") + "/profile_2"

    fetch(profileForm2Url, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.errors) {
          if (data.errors.invalid_token) {
            localStorage.removeItem('jwt');
            window.location.href = wolData.urls.frontUrl + "/sign-in";
          }
          // GA Tracking: Profile 2 with errors (track after invalid token redirection)
          ReactGA.event({ category: 'candidate', action: 'profile2-with-errors' });

        } else {
          setStatus({ successFormSubmission: true })
          if (data.cv_initial_values.filename && data.cv_initial_values.url) {
            setCvInitialValues(data.cv_initial_values)
          }

          //Tracking:
          // GA : Profile 2 completed successfully
          ReactGA.event({ category: 'candidate', action: 'profile2-completed-successfully' });
          // Mixpanel
          mixpanel.track('Profile 2 changes saved')

          // TODO: Use history instead window.location
          if (formFlow === 'application') {
            if (localStorage.getItem("applyingToJobId") === null) {
              window.location.href = wolData.urls.frontUrl + "/jobs";
            } else {
              window.location.href = wolData.urls.frontUrl + "/apply-to?job_id=" + localStorage.getItem("applyingToJobId") + "&candidate_id=" + localStorage.getItem("candidateId");
            }

          } else if (formFlow === 'salary') {
            window.location.href = wolData.urls.frontUrl + '/show-me-the-money';

          } else {
            setStatus({ successFormSubmission: true })
            setTimeout(() => { setStatus({ successFormSubmission: false }) }, 6000);
          }
        }
      })
      .catch((errors) => {
        console.error(errors)
      });
  }

  return isCandidateLoaded ? (
    <Formik
      initialValues={ getInitialValues(candidate, formFlow) }
      validationSchema={getValidationSchema(localStorage.getItem('userSite'), formFlow)}
      onSubmit={candidateProfileForm2OnSubmit}
    >
      {({
        values,
        errors,
        status,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit }
      ) => (
        <Row>
          <Col>
            <CustomFlowTitle formFlow={formFlow} />

            <Form onSubmit={handleSubmit} className='wol-form'>

              <h2 className='mb-5' style={{ fontWeight: 500 }}>{t('CandidateProfileForm2.formTitle')}</h2>

              <Form.Group className="form-group-custom">
                <Form.Label>{t('CandidateProfileForm2.surnameLabel')} *</Form.Label>
                <Form.Control
                  name="surname"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.surname}
                  className={utils.fieldClassName('surname', status, touched, errors)}
                />
                {utils.displayFieldError('surname', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>{t('CandidateProfileForm2.secondSurnameLabel')} *</Form.Label>
                <Form.Control
                  name="second_surname"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.second_surname}
                  className={utils.fieldClassName('second_surname', status, touched, errors)}
                />
                {utils.displayFieldError('second_surname', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>Nombre *</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  className={utils.fieldClassName('name', status, touched, errors)}
                />
                {utils.displayFieldError('name', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>{t('CandidateProfileForm2.phoneNumberLabel')} *</Form.Label>
                <Form.Control
                  name="phone_number"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  placeholder={t('CandidateProfileForm2.phoneNumberPlaceholder')}
                  className={utils.fieldClassName('phone_number', status, touched, errors)}
                />
                {utils.displayFieldError('phone_number', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>¿Cuál es el motivo de la búsqueda de nuevas alternativas? *</Form.Label>
                <Form.Control
                  name="driver_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driver_id}
                  as="select"
                  className={utils.fieldClassName('driver_id', status, touched, errors)}
                >
                  {utils.getDriversBySite(localStorage.getItem("userSite")).map((driver) => (
                    <option key={driver.value} value={driver.value}>
                      {driver.label}
                    </option>
                  ))}
                </Form.Control>
                {utils.displayFieldError('driver_id', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>
                  ¿En qué ciudad resides? *<br></br>
                  <small>Preguntamos tu ciudad de residencia para ofrecerte trabajos cercanos.</small>
                </Form.Label>
                <Form.Control
                  name="city_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city_id}
                  as="select"
                  className={utils.fieldClassName('city_id', status, touched, errors)}
                >
                  <option key={0} value=""></option>
                  {fields_default_values.cities.map((city) => (
                    <option key={city.value} value={city.value}>
                      {city.label}
                    </option>
                  ))}
                </Form.Control>
                {utils.displayFieldError('city_id', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>
                  {t('CandidateProfileForm2.pretensionOfIncomeSinceLabel')} *<br></br>
                  <small>Corresponde a tu pretensión de renta genérica. Al postular también podrás indicar cuál es tu expectativa de renta para una oferta de trabajo en particular.</small>
                </Form.Label>
                <Form.Control
                  name="pretension_of_income_since"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pretension_of_income_since}
                  as="select"
                  className={utils.fieldClassName('pretension_of_income_since', status, touched, errors)}
                >
                  <option value=""></option>
                  {utils.getSalariesBySite(localStorage.getItem("userSite")).map((s) => (
                    <option key={s.value} value={s.value}>
                      {s.label}
                    </option>
                  ))}
                </Form.Control>
                {utils.displayFieldError('pretension_of_income_since', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>
                  {t('CandidateProfileForm2.lastNetSalaryLabel')}
                  {displayLastSalaryShowMeTheMoneyLabel(formFlow)}
                </Form.Label>
                <Form.Control
                  name="last_net_salary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_net_salary}
                  as="select"
                  className={utils.fieldClassName('last_net_salary', status, touched, errors)}
                >
                  <option value=""></option>
                  <option key="0" value="0">$0</option>
                  {utils.getSalariesBySite(localStorage.getItem('userSite')).map((s) => (
                    <option key={s.value} value={s.value}>
                      {s.label}
                    </option>
                  ))}
                </Form.Control>
                {utils.displayFieldError('last_net_salary', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label className="form-label-custom">
                  <p>¿Cuántos sueldos de bono ganaste el {(currentYear - 1)}? <strong>*</strong></p>
                </Form.Label>
                <Form.Control
                  name="bonus_salary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bonus_salary}
                  as="select"
                  className={utils.fieldClassName('bonus_salary', status, touched, errors)}
                >
                  <option></option>
                  {annualBonusDefaultValues.map((opt) => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
                </Form.Control>
                {utils.displayFieldError('bonus_salary', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>
                  Url de Linkedin<br />
                  <small>Puedes aportar mayor información de tu perfil profesional en tus postulaciones copiando y pegando la url de tu perfil de Linkedin.</small>
                </Form.Label>
                <Form.Control
                  name="linkedin_url"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.linkedin_url}
                  type="text"
                  className={utils.fieldClassName('linkedin_url', status, touched, errors)}
                >
                </Form.Control>
                {utils.displayFieldError('linkedin_url', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>¿Cuál es tu fecha de nacimiento? *</Form.Label>
                <Form.Control
                  name="birthdate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birthdate}
                  type="date"
                  className={utils.fieldClassName('birthdate', status, touched, errors)}
                >
                </Form.Control>
                {utils.displayFieldError('birthdate', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>{t('CandidateProfileForm2.dni')}</Form.Label>
                <Form.Control
                  name="dni"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dni}
                  type="text"
                  placeholder={t('CandidateProfileForm2.dniPlaceholder')}
                  className={utils.fieldClassName('dni', status, touched, errors)}
                >
                </Form.Control>
                {utils.displayFieldError('dni', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>
                  Género *<br></br>
                  <small>¿Por qué preguntamos esto? Nos ayuda a entregarte estudios de mercado más específicos y permite que hagamos nuestro aporte a una mayor inclusión. Lo medimos precisamente porque nos importa.</small>
                </Form.Label>
                <Form.Control
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  as="select"
                  className={utils.fieldClassName('gender', status, touched, errors)}
                >
                  <option key={0} value=""></option>
                  {wolData.genderOptions.map((g) => (
                    <option key={g.value} value={g.value}>
                      {g.label}
                    </option>
                  ))}
                </Form.Control>
                {utils.displayFieldError('gender', status, touched, errors)}
              </Form.Group>

              <Form.Group className="form-group-custom">
                <Form.Label>
                  {t('CandidateProfileForm2.cv')} *<br></br>
                  <small>Para actualizar tu CV solo debes volver a subir el nuevo archivo</small>
                </Form.Label>
                <Form.File
                  name="cv"
                  type="file"
                  onBlur={handleBlur}
                  className={utils.fieldClassName('cv', status, touched, errors)}
                  onChange={(event) => {
                    setFieldValue("cv", event.currentTarget.files[0]);
                  }}
                />
                {utils.displayFileData(cvInitialValues)}
                {utils.displayFieldError('cv', status, touched, errors)}
              </Form.Group>


              <WolFormAlert status={status}
                errors={errors}
                errorMessageKey={'candidateProfileError'}
                successMessageKey={'candidateProfileSuccess'} />

              <CustomFlowSubmit
                formFlow={formFlow}
                profile={'profile-2'}
                isSubmitting={isSubmitting}
              />

            </Form>
          </Col>
        </Row>
      )
      }
    </Formik>

  ) : (<WolSpinner />)
}
export default ProfileForm2;
