import utils from "./utils";

export type SessionData = {
  session: string;
  current_user: {
    site: string;
    email: string;
    name: string;
    surname: string;
    candidate_id: string;
    university: string;
    primary_speciality: string;
  }
}

const session = {

  create: (data: SessionData) => {
    localStorage.setItem('jwt', data.session);
    localStorage.setItem('userSite', data.current_user.site);
    localStorage.setItem('userEmail', data.current_user.email);
    localStorage.setItem('userName', data.current_user.name);
    localStorage.setItem('userSurname', data.current_user.surname);
    localStorage.setItem('candidateId', data.current_user.candidate_id);
    localStorage.setItem('candidateUniversity', data.current_user.university);
    localStorage.setItem('candidateSpeciality', data.current_user.primary_speciality);
    localStorage.setItem('i18nextLng', utils.getLanguage(data.current_user.site));
  },

  destroy: () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('userSite');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('candidateId');
    localStorage.removeItem('candidateUniversity');
    localStorage.removeItem('candidateSpeciality');
    localStorage.removeItem('i18nextLng');
  }
}

export default session;

