import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Ingrese email')
});

const handleSubmit = async (values, { setSubmitting, setStatus }) => {
  try {
    setSubmitting(true);
    setStatus({ responseMessage: null })
    setTimeout(() => { setSubmitting(false) }, 500);

    const url = `${wolData.urls.APIUrl}/password_recovery`;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: values.email })
    };

    const res = await utils.fetch(url, requestOptions);

    if(res.status === 200){
      utils.trackMixpanelEvent('Successful password recovery', {recovered_account: values.email})
      ReactGA.event({ category: 'candidate', action: 'password-recovery-request' });
      setStatus({ responseMessage: res.body.message })
    }

    setSubmitting(false)

  } catch (error) {
    console.log(error);
  }
}

const PasswordRecoveryForm = () => {

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values,
        errors,
        status,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting }) => (

        <Container className='wol-container mb-5'>
          <Row>
            <Col>
              <h2 className='mb-3 text-center'>Recuperar Contraseña</h2>
            </Col>
          </Row>

          <Row className='container-center mx-auto text-justify text-center'>
            <Col>
              {
                status && status.responseMessage ?
                  <Alert className='mt-4' variant='success'>{status.responseMessage}</Alert> : null
              }
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              <Form onSubmit={handleSubmit} className='container-center mx-auto wol-form'>

                {
                  status && status.responseMessage ? null :
                    <Row>
                      <Col className='text-center'>
                        Ingresa el email utilizado para crear tu cuenta y te enviaremos las instrucciones de recuperación en los próximos minutos.
                      </Col>
                    </Row>
                }

                <Row className='mt-4'>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name='email'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        size="lg"
                        className={touched.email && errors.email ? 'error' : null}
                      />
                      {
                        touched.email && errors.email ?
                          <div className='error-message'>{errors.email}</div> : null
                      }
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col>
                    <Button variant='danger' type='submit' className='btn-block' size='lg' disabled={isSubmitting}>
                      Enviar instrucciones
                    </Button>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col className='text-center mt-4'>
                    <a href={wolData.urls.frontUrl + '/sign-in'}>Iniciar sesión</a>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      )
      }
    </Formik>
  )
}

export default PasswordRecoveryForm;
