import './app.scss';
import { Switch, Redirect, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { Container } from 'react-bootstrap'
import './App.css';

import Header from "./components/commons/Header";
import RegistrationForm from "./components/signUp/RegistrationForm"
import SignInForm from "./components/signIn/SignInForm"
import PasswordRecoveryForm from "./components/passwordRecovery/PasswordRecoveryForm"
import ChangePasswordForm from "./components/changePassword/ChangePasswordForm"

import Profile from "./components/profile/Profile"
import ProfileForm from "./components/candidateProfile/ProfileForm"
import ProfileForm2 from "./components/candidateProfile2/ProfileForm2"
import ProfileForm3 from './components/candidateProfile3/ProfileForm3'
import Jobs from "./components/jobs/Jobs"
import Application from "./components/applyTo/Application"
import ApplicationIntro from "./components/applyTo/ApplicationIntro"
import OpenToHire from "./components/applicationAndCareer/OpenToHire"
import ShowMeTheMoneyApplication from "./components/showMeTheMoney/Application"
import Auth from './components/commons/Auth';
import utils from './helpers/utils';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next';
import global_cl from "./translations/es-CL/global.json";
import global_co from "./translations/es-CO/global.json";
import global_mx from "./translations/es-MX/global.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage']
    },
    resources: {
      "es-CL": { global: global_cl },
      "es-CO": { global: global_co },
      "es-MX": { global: global_mx },
    },
    fallbackLng: "es-CL",
    cookieMinutes: 30,
    interpolation: { escapeValue: false }
  })

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {api_host: process.env.API_HOST});


function App() {
  const [t, I18n] = useTranslation("global");
  let query = useQuery();
  const querySite = query.get("site")
  if (querySite) {
    const lng = utils.getLanguage(querySite)
    if (lng !== I18n.language) {
      i18n.changeLanguage(lng)
      localStorage.setItem("i18nextLng", lng)
    }
  }

  return (
    <div className='app'>
      <Header />

      <Switch>
        <Auth
          path={"/sign-up"}
          component={<RegistrationForm openToHireId={query.get('open_to_hire_id')} universityCode={query.get('university_code')} />}
          toRedirect={<Redirect to="/jobs" />}
          tokenRequired={false}
        />

        <Auth
          path={"/password-recovery"}
          component={<PasswordRecoveryForm />}
          toRedirect={<Redirect to="/jobs" />}
          tokenRequired={false}
        />

        <Auth
          path={"/sign-in"}
          component={<SignInForm openToHireId={query.get("open_to_hire_id")} registrationStatus={query.get("registration_status")} candidateEmail={query.get("candidate_email")} />}
          toRedirect={<Redirect to="/jobs" />}
          tokenRequired={false}
        />

        <Auth
          path={"/profile"}
          component={<Profile />}
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/postulacion-y-carrera"}
          component={<OpenToHire />}
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/show-me-the-money"}
          component={<ShowMeTheMoneyApplication />}
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/candidate-profile"}
          component={
            <Container className='wol-container'>
              <ProfileForm formFlow={query.get("form_flow")}/>
            </Container>
          }
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/candidate-profile-2"}
          component={
            <Container className='wol-container'>
              <ProfileForm2 formFlow={query.get("form_flow")} />
            </Container>
          }
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/candidate-profile-3"}
          component={
            <Container className='wol-container'>
              <ProfileForm3 formFlow={query.get("form_flow")} />
            </Container>
          }
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/jobs"}
          component={<Jobs />}
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/apply-to"}
          component={<ApplicationIntro site={query.get("site")} openToHireId={query.get('open_to_hire_id')} />}
          toRedirect={<Application jobId={query.get("job_id")} />}
          tokenRequired={false}
        />

        <Auth
          path={"/change-password"}
          component={<ChangePasswordForm />}
          toRedirect={<Redirect to="/sign-in" />}
          tokenRequired={true}
        />

        <Auth
          path={"/"}
          component={<Redirect to="/sign-in" />}
          toRedirect={<Redirect to="/jobs" />}
          tokenRequired={false}
        />

      </Switch>

      <footer className='text-center' >
        ¿Tienes alguna duda o comentario sobre el funcionamiento de la plataforma?<br></br>
        escríbenos a &nbsp;<a href="mailto:jobs@workon.law">jobs@workon.law.</a>
      </footer>

    </div>
  );
}
export default App;
