import WolSpinner from '../commons/WolSpinner';
import JobItem from './JobItem';

const JobsList = ({ jobs, showJobs, setWorkExperienceYear}) => {
  return showJobs ? (
    <div>
      {jobs.map((item, index) => <JobItem item={item} setWorkExperienceYear={setWorkExperienceYear} key={`job-list-${index}`} />)}
    </div>
  ) : (<WolSpinner />)
}

export default JobsList;