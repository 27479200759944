import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';

import WolSpinner from '../commons/WolSpinner';
import Results from './Results';
import mixpanel from 'mixpanel-browser';
import { Results as ResultsType } from './SharedTypes';
import ProfileCo from './ProfileCo';

import wolData from '../../helpers/wolData';
import session from '../../helpers/session';

type RenderData = {
  name: string;
  profile_1_is_ready: boolean;
  profile_2_is_ready: boolean;
  profile_3_is_ready: boolean;
  show_me_the_money_1_is_ready: boolean;
  show_me_the_money_2_is_ready: boolean;
  show_me_the_money_3_is_ready: boolean;
  status: string;
  results: ResultsType;
}

const Step1 = ({ renderData }: {renderData: RenderData}) => {
  if(renderData.show_me_the_money_1_is_ready === false){
    return <li><a href="/candidate-profile?form_flow=salary">Formación Académica</a></li>;
  }else{
    return null;
  }
}

const Step2 = ({ renderData }: {renderData: RenderData}) => {
  if(renderData.show_me_the_money_2_is_ready === false){
    return <li><a href="/candidate-profile-2?form_flow=salary">Expectativas de Renta y CV</a></li>;
  }else{
    return null;
  }
}

const Step3 = ({ renderData }: {renderData: RenderData}) => {
  if((renderData.profile_3_is_ready === false) || (localStorage.getItem('jobHistoryConfirmed') !== 'true')){
    return <li><a href="/candidate-profile-3?form_flow=salary">Experiencia profesional</a></li>;
  }else{
    return null;
  }
}

const ShowMeTheMoneyApplication = () => {
  const site: string | null = localStorage.getItem('userSite');
  const [data, setData] = useState<RenderData | null>(null)

  const renderShowMeTheMoneyView = (renderData: RenderData | null) => {
    if (renderData === null) { return <WolSpinner /> }

    if (renderData.status !== 'completed' || (localStorage.getItem('jobHistoryConfirmed') !== 'true')) {
      return (
        <Container id='show-me-the-money-container' className='wol-container mb-5'>
          <Row>
            <Col>
              <h2 className='mb-4'>Show me the money</h2>
              <h5>
                Descubre cuánto ganan los abogados con un perfil similar al tuyo y más. Sólo debes completar la siguiente información:
              </h5>
              <Row>
                <Col>
                  <ul className='mt-4'>
                    <Step1 renderData={renderData}/>
                    <Step2 renderData={renderData}/>
                    <Step3 renderData={renderData}/>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )
    }

    if(site === 'CO'){ return <ProfileCo /> }

    return <Results data={renderData.results} />;
  }

  async function fetchShowMeTheMoneyData() {
    const requestOptions = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwt') } };
    const url = wolData.urls.APIUrl + "/candidates/get_show_me_the_money_form";
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    if (responseData.errors && responseData.errors.invalid_token) {
      session.destroy();
      window.location.href = wolData.urls.frontUrl + "/sign-in";
    }
    setData(responseData);
  }

  useEffect(() => {
    fetchShowMeTheMoneyData();
    mixpanel.track('ShowMeTheMoney: Application')
  }, []);

  return renderShowMeTheMoneyView(data)
}

export default ShowMeTheMoneyApplication;
